<template>
  <div class="hidden-print">
    <!-- begin #sidebar -->
    <div id="sidebar" class="sidebar hidden-print" v-show="USERPROFILE.taxi_associations_id">
      <!-- begin sidebar scrollbar -->
      <div data-scrollbar="true" data-height="100%">
        <!-- begin sidebar user -->
        <ul class="nav">
          <!-- begin nav-profile -->
          <navprofileComp :USERPROFILE="USERPROFILE" />
          <!-- begin nav-profile -->
          <li>
            <ul class="nav nav-profile">
              <li>
                <router-link  :to="{ name: 'systemuserprofile', params: { id: USERID }}">
                  <i class="ion-ios-cog"></i> Profile
                </router-link>
              </li>
              <li>
                <a href="/">
                  <i class="ion-md-log-out"></i> Log Out
                </a>
              </li>
            </ul>
          </li>
        </ul>
        <!-- end sidebar user -->
        <!-- begin sidebar nav -->
        <ul class="nav">
          <li class="nav-header">Navigation</li>
          <li class="has-sub" :class="{'active': ActiveSubMenu==='Dashboard'}">
            <a href="javascript:;">
              <b class="caret"></b>
              <i class="fas fa-tachometer-alt"></i>
              <span>Dashboard</span>
            </a>
            <ul class="sub-menu">
              <li v-if="USERGROUPCODE==='ISA'" :class="{'active': ActiveMenu==='Summary'}">
                <router-link to="/SysAdmin/Dashboard">Summary</router-link>
              </li>
              <!-- <li v-if="USERGROUPCODE==='ISA' || USERGROUPCODE==='RUS'" :class="{'active': ActiveMenu==='Summary'}">
                <router-link to="/RegionalChairman/TaxiAssociationDashboard">Taxi Ass Region Summary</router-link>
              </li> -->
              <!-- <li v-if="USERGROUPCODE==='ISA' || USERGROUPCODE==='RUS'" :class="{'active': ActiveMenu==='Summary'}">
                <router-link to="/RegionalChairman/Dashboard">Region Summary</router-link>
              </li> -->
              <li v-if="USERGROUPCODE==='ISA' || USERGROUPCODE==='RUS'" :class="{'active': ActiveMenu==='Summary'}">
                <router-link to="/Regional/Dashboard">Summary</router-link>
              </li>
              <li v-if="USERGROUPCODE==='ISA' || USERGROUPCODE==='RUS'" :class="{'active': ActiveMenu==='RankInfo'}">
                <router-link to="/RankManager/Meta/Region">Rank Info</router-link>
              </li>
              <li v-if="USERGROUPCODE==='ISA' || USERGROUPCODE==='RUS'" :class="{'active': ActiveMenu==='RankStats'}">
                <router-link to="/RankManager/History/Region">Rank Stats</router-link>
              </li>
              <li v-if="USERGROUPCODE==='ISA' || USERGROUPCODE==='RUS'" :class="{'active': ActiveMenu==='RankRealtime'}">
                <router-link to="/RankManager/Region/R">Rank Realtime</router-link>
              </li>
              <li v-if="USERGROUPCODE==='ISA' || USERGROUPCODE==='RUS'" :class="{'active': ActiveMenu==='Operators'}">
                <router-link to="/RankManager/Region/O">Operators</router-link>
              </li>
              <li v-if="USERGROUPCODE==='ISA' || USERGROUPCODE==='RUS'" :class="{'active': ActiveMenu==='Taxis'}">
                <router-link to="/RankManager/Region/T">Taxis</router-link>
              </li>
              <li v-if="USERGROUPCODE==='ISA' || USERGROUPCODE==='RUS'" :class="{'active': ActiveMenu==='Drivers'}">
                <router-link to="/RankManager/Region/D">Drivers</router-link>
              </li>
              <!-- <li v-if="USERGROUPCODE==='CHM'" :class="{'active': ActiveMenu==='Summary'}">
                <router-link to="/RegionalChairman/Dashboard">Region Summary</router-link>
              </li> -->
              <!-- <li v-if="USERGROUPCODE==='ISA'" :class="{'active': ActiveMenu==='Summary'}"> -->
               <li v-if="USERGROUPCODE==='ISA'" :class="{'active': ActiveMenu==='Summary'}">
                <router-link to="/RegionalChairman/TaxiAssociationDashboard">Taxi Ass Dashboard</router-link>
              </li>
               <!-- <li v-if="USERGROUPCODE==='CHM'" :class="{'active': ActiveMenu==='Summary'}">
                <router-link to="/RegionalChairman/TaxiAssociationDashboard">Taxi Ass Dashboard</router-link>
              </li> -->
              <li v-if="USERGROUPCODE==='ISA'" :class="{'active': ActiveMenu==='Finance'}">
                <router-link to="/SysAdmin/Finance">Finance</router-link>
              </li>
               <li v-if="USERGROUPCODE==='ISA'" :class="{'active': ActiveMenu==='Summary'}">
                <router-link to="/SysAdmin/Operational">Operational</router-link>
              </li>
               <li v-if="USERGROUPCODE==='CHM'" :class="{'active': ActiveMenu==='Summary'}">
                <router-link to="/Chairman/Dashboard">Summary</router-link>
                <router-link to="/CollectionManager/Dashboard">Money Dashboard</router-link>
                <router-link to="/Chairman/VehicleDashboard">Vehicle Dashboard</router-link>
              </li>
               <li v-if="USERGROUPCODE==='ISA'" :class="{'active': ActiveMenu==='Summary'}">
                <router-link to="/Chairman/Dashboard">Chairman Summary</router-link>
                <router-link to="/Secretary/ManageRoute">Manage Routes</router-link>
              </li>
              <!-- <li v-if="USERGROUPCODE==='CHM'" :class="{'active': ActiveMenu==='Summary'}">
                <router-link to="/RankManager/Dashboard">Rank Manager</router-link>
              </li> -->
              <li v-if="USERGROUPCODE==='SGL'" :class="{'active': ActiveMenu==='Summary'}">
                <router-link to="/Secretary/Dashboard">Summary</router-link>
                <router-link to="/Secretary/ManageRoute">Manage Routes</router-link>
              </li>
              <li v-if="USERGROUPCODE==='TRS'" :class="{'active': ActiveMenu==='Summary'}">
                <router-link to="/Treasurer/Dashboard">Summary</router-link>
              </li>
              <li v-if="USERGROUPCODE==='DCL'" :class="{'active': ActiveMenu==='Summary'}">
                <router-link to="/Collector/Dashboard">Summary</router-link>
              </li>
              <li v-if="USERGROUPCODE==='ISA'" :class="{'active': ActiveMenu==='Summary'}">
                <router-link to="/CollectionManager/Dashboard">Collection Dashboard</router-link>
                <router-link to="/Chairman/VehicleDashboard">Taxi Dashboard</router-link>
              </li>
              <li v-if="USERGROUPCODE==='CMR'" :class="{'active': ActiveMenu==='Summary'}">
                <router-link to="/CollectionManager/Dashboard">Summary</router-link>
              </li>
              <li v-if="USERGROUPCODE==='GMG'" :class="{'active': ActiveMenu==='Summary'}">
                <router-link to="/GrievanceManager/Dashboard">Summary</router-link>
              </li>
              <li v-if="USERGROUPCODE==='GOF'" :class="{'active': ActiveMenu==='Summary'}">
                <router-link to="/GrievanceOffices/Dashboard">Summary</router-link>
              </li>
              <li v-if="USERGROUPCODE==='OCK'" :class="{'active': ActiveMenu==='Summary'}">
                <router-link to="/OfficeClerk/Dashboard">Summary</router-link>
              </li>
              <li v-if="USERGROUPCODE==='PUS'" :class="{'active': ActiveMenu==='Summary'}">
                <router-link to="/Provincial/Dashboard">Summary</router-link>
              </li>

              <li v-if="USERGROUPCODE==='TRS'" :class="[{'active' :ActiveMenu==='Summary'}]">
                <router-link to="/FuelManager/Dashboard/">Fuel Dashboard</router-link>
              </li>
              <li v-if="USERGROUPCODE==='TRS'" :class="{'active': ActiveMenu==='Summary'}">
                <router-link to="/CollectionManager/Dashboard">Collections</router-link>
              </li>
              
              <!-- <li v-if="USERGROUPCODE==='RUS'" :class="{'active': ActiveMenu==='Summary'}">
                <router-link to="/RegionalChairman/Dashboard">Summary</router-link>
              </li> -->
              <li v-if="USERGROUPCODE==='NUS'" :class="{'active': ActiveMenu==='Summary'}">
                <router-link to="/National/Dashboard">Summary</router-link>
              </li>
             
            </ul>
          </li>

          
          
          <li data-role="CVFR" :class="['has-sub',{'active': ActiveSubMenu==='FuelManager','byrole': !ISINTERNALSYSTEMUSER}]">
            <a href="javascript:;">
              <b class="caret"></b>
              <i class="fas fa-gas-pump bg-orange"></i>
              <span>Fuel Manager</span>
            </a>
            <ul class="sub-menu">
               <!-- <li data-role="ITO" :class="[{'active' :ActiveMenu==='Dashboard'}]">
                <router-link to="/TPAY/Dashboard">Dashboard</router-link>
              </li> -->
               <li data-role="CVFR" :class="[{'byrole': !ISINTERNALSYSTEMUSER,'active' :ActiveMenu==='FuelManagerDashboard'}]">
                <router-link to="/FuelManager/Dashboard/">Dashboard</router-link>
              </li>
              <li data-role="AVFT" :class="[{'byrole': !ISINTERNALSYSTEMUSER,'active' :ActiveMenu==='AddFuelType'}]">
                <router-link to="/FuelManager/AddFuelTypes/">Fuel Type</router-link>
              </li>
            </ul>
          </li>

          <li v-if="ISTPAYAGENT" :class="['has-sub',{'active': ActiveSubMenu==='BookASeat'}]">
            <a href="javascript:;">
              <b class="caret"></b>
              <i class="fas fa-chair bg-blue"></i>
              <span>Book A Seat</span>
            </a>
            <ul class="sub-menu">
               <!-- <li data-role="ITO" :class="[{'active' :ActiveMenu==='Dashboard'}]">
                <router-link to="/TPAY/Dashboard">Dashboard</router-link>
              </li> -->
               <li  v-if="ISINTERNALSYSTEMUSER" :class="[{'active' :ActiveMenu==='BookingDashboard'}]">
                <router-link to="/TPAY/Dashboard/BookASeat/">Booking Dashboard</router-link>
              </li>
              <li  v-if="ISINTERNALSYSTEMUSER" :class="[{'active' :ActiveMenu==='BookingsInternal'}]">
                <router-link to="/BookASeat/Bookings/Details/Internal">Upcoming Bookings Internal</router-link>
              </li>
              <li  v-if="ISTPAYAGENT" :class="[{'active' :ActiveMenu==='BookingsInternal'}]">
                <router-link to="/BookASeat/ViewBookings">All Bookings</router-link>
              </li>
              <li v-if="ISTPAYAGENT" :class="[{'active' :ActiveMenu==='Bookings'}]">
                <router-link to="/BookASeat/Bookings/Details/">Upcoming Bookings</router-link>
              </li>
              <li v-if="ISTPAYAGENT" :class="[{'active' :ActiveMenu==='PublicHolidays'}]">
                <router-link to="/BookASeat/PublicHolidays">Public Holiday</router-link>
              </li>
              <!-- <li v-if="ISINTERNALSYSTEMUSER" :class="[{'active' :ActiveMenu==='All Bookings'}]">
                <router-link to="/TPAY/Bookings/All">Bookings</router-link>
              </li> -->
              <li v-if="ISTPAYAGENT" :class="[{'active' :ActiveMenu==='AddCommuter'}]">
                <router-link to="/BookASeat/Commuters/New/:id">Add Commuter</router-link>
              </li>
               <li v-if="ISTPAYAGENT" :class="[{'active' :ActiveMenu==='SearchUsers'}]">
                <router-link to="/BookASeat/Users/FullSearch">Search All Users</router-link>
              </li>
              <li v-if="ISTPAYAGENT" :class="[{'active' :ActiveMenu==='AddDriver'}]">
                <router-link to="/BookASeat/Driver/New/:id">Add Driver</router-link>
              </li>
              <li v-if="ISTPAYAGENT" :class="[{'active' :ActiveMenu==='AddOperator'}]">
                <router-link to="/BookASeat/Operator/New/:id">Add Operator</router-link>
              </li>
              <li v-if="ISTPAYAGENT" :class="[{'active' :ActiveMenu==='AddAgent'}]">
                <router-link to="/BookASeat/Agent/New/:id">Add Agent</router-link>
              </li>
              <li v-if="ISTPAYAGENT" :class="[{'active' :ActiveMenu==='NewVehicle'}]">
                <router-link to="/BookASeat/Vehicle/NewVehicle/:id">Add Vehicle</router-link>
              </li>
              <li v-if="ISTPAYAGENT" :class="[{'active' :ActiveMenu==='TaxiRank'}]">
                <router-link to="/BookASeat/Rank">Taxi Rank</router-link>
              </li>
              <li v-if="ISTPAYAGENT" :class="[{'active' :ActiveMenu==='TaxiRoute'}]">
                <router-link to="/BookASeat/Route">Taxi Route</router-link>
              </li>
              <li v-if="ISINTERNALSYSTEMUSER" :class="[{'active' :ActiveMenu==='DepartureTimes'}]">
                <router-link to="/BookASeat/Departure">Departure Times</router-link>
              </li>
             
                <li v-if="ISINTERNALSYSTEMUSER" :class="{'active': ActiveMenu==='CallBack'}">
                <router-link to="/TPAY/RequestCallBack">Call Back Request</router-link>
               </li>
             
               
            </ul>
          </li>

          
          <li data-role="RTO" v-if="ISINTERNALSYSTEMUSER" :class="['has-sub',{'active': ActiveSubMenu==='RentATaxi','byrole': !ISINTERNALSYSTEMUSER}]">
            <a href="javascript:;">
              <b class="caret"></b>
             <i class="fas fa-bus bg-blue"></i>
              <span>Rent A Taxi</span>
            </a>
            <ul class="sub-menu">
               <!-- <li data-role="ITO" :class="[{'active' :ActiveMenu==='RentalBookingDashboard'}]">
                <router-link to="/TPAY/Dashboard/RentalDashboard">Rental Dashboard</router-link>
              </li> -->
              <li :class="[{'active' :ActiveMenu==='RentalBookings'}]">
                <router-link to="/TPAY/Rental/Summary">Rental Summary</router-link>
              </li>
              <li :class="[{'active' :ActiveMenu==='All RentalBookings'}]">
                <router-link to="/TPAY/Rental/All">Rental Bookings</router-link>
              </li>
              
                <li :class="{'active': ActiveMenu==='CallBack'}">
                <router-link to="/TPAY/RequestCallBack">Call Back Request</router-link>
               </li>
            </ul>
          </li>

          <li
            class="has-sub"
            :class="{'active': ActiveSubMenu==='TaxiAssociation'}"
            v-if="ISINTERNALSYSTEMUSER"
          >
            <a href="#">
              <b class="caret"></b>
              <i class="ion-ios-business bg-gradient-blue"></i>
              <span>Taxi Association</span>
            </a>
            <ul class="sub-menu">
              <li
                :class="{'active' :ActiveMenu==='AddTaxiAssociation'}"
                v-if="ISINTERNALSYSTEMUSER || USERGROUPCODE==='CHM'"
              >
                <router-link to="/TaxiAssociation/Add">Add Taxi Association</router-link>
              </li>
              <li
                v-if="ISINTERNALSYSTEMUSER || USERGROUPCODE==='CHM'"
                :class="{'active' :ActiveMenu==='ManageTaxiAssociation'}"
              >
                <router-link to="/TaxiAssociation/Manage">Manage Taxi Association</router-link>
              </li>
              <li
                v-if="ISINTERNALSYSTEMUSER || USERGROUPCODE==='CHM'"
                :class="{'active' :ActiveMenu==='AddTaxiAssociationRoute'}"
              >
                <router-link
                :to="{ name: 'taxiassociationRoutes', params: { id: context.taxiassociationid }}" 
                >Taxi Association Routes</router-link>
              </li>
              <li
                v-if="ISINTERNALSYSTEMUSER || USERGROUPCODE==='CHM'"
                :class="{'active' :ActiveMenu==='ManageTaxiAssociationRanks'}"
              >
                <router-link to="/TaxiAssociation/Ranks">Taxi Association Ranks</router-link>
              </li>

              <li
                v-if="ISINTERNALSYSTEMUSER || USERGROUPCODE==='CHM'"
                :class="{'active' :ActiveMenu==='LetterOfGoodStanding'}"
              >
                <router-link to="/TaxiAssociation/GoodStanding">Letter Of Good Standing</router-link>
              </li>
              <li
                v-if="ISINTERNALSYSTEMUSER || USERGROUPCODE==='CHM'"
                :class="{'active' :ActiveMenu==='TaxiAssociationSpotFines'}"
              >
                <router-link
                        :to="{ name: 'taxiassociationspotfines', params: { id: context.taxiassociationid } }"
                      >Taxi Association Spot Fines</router-link>
              </li>
              <!-- <li
                v-if="ISINTERNALSYSTEMUSER"
                :class="{'active' :ActiveMenu==='TaxiAssociationProfile'}"
              >
                <router-link to="/TaxiAssociation/UpdateBilling">Update Billing</router-link>
              </li> -->

              <li
                v-if="ISINTERNALSYSTEMUSER"
                :class="{'active' :ActiveMenu==='AddTaxiAffiliation'}"
              >
              <router-link to="/TaxiAssociation/AddTaxiAffiliation">Add Taxi Affiliation</router-link>
              
              </li>

              <li
                v-if="ISINTERNALSYSTEMUSER"
                :class="{'active' :ActiveMenu==='AddOnBillings'}"
              >
                <router-link to="/TaxiAssociation/AddBilling">Add On Billings</router-link>
              </li>
            </ul>
          </li>


          <li
            class="has-sub"
            :class="{'active': ActiveSubMenu==='RankManager','byrole': !ISINTERNALSYSTEMUSER}"
            data-role="CVR"
          >
            <a href="#">
              <b class="caret"></b>
              <i class="fas fa-registered bg-gradient-purple"></i>
              <span>Rank Manager</span>
            </a>
            <ul class="sub-menu">
              <li :class="{'active': ActiveMenu==='RankInformation'}">
                <router-link to="/RankManager/Meta">Rank Information</router-link>
              </li>
              <li :class="{'active': ActiveMenu==='RealtimeRankActivities'}">
                <router-link to="/RankManager/">Realtime</router-link>
              </li>
              <li v-if="USERGROUPCODE==='ISA' || USERGROUPCODE==='RGR' || USERGROUPCODE==='CHM' || USERGROUPCODE==='SGL'" :class="{'active': ActiveMenu==='RankManagerStats'}">
                <router-link to="/RankManager/History">Rank Manager History</router-link>
              </li>

            </ul>
          </li>

          <li
            class="has-sub"
            :class="{'active': ActiveSubMenu==='PrepaidServices','byrole': !ISINTERNALSYSTEMUSER}"
            data-role="VPPS"
            
          >
            <a href="#">
              <b class="caret"></b>
              <i class="fa fa-dice bg-gradient-yellow"></i>
              <span>Prepaid Services</span>
            </a>
            <ul class="sub-menu">
              <li :class="{'active': ActiveMenu==='AddMerchant'}">
                <router-link to="/prepaidServices/Add">Add Merchant</router-link>
              </li>
              <li :class="{'active': ActiveMenu==='List'}">
                <router-link to="/prepaidServices/merchants">List</router-link>
              </li>
            </ul>
          </li>

          <li
            class="has-sub hide"
            :class="{'active': ActiveSubMenu==='OnlineServices'}"
          >
            <a href="#">
              <b class="caret"></b>
              <i class="fas fa-cart-plus bg-gradient-pink"></i>
              <span>Online Services</span>
            </a>
            <ul class="sub-menu">
              <li data-role="VPPS" :class="{'active hide': ActiveMenu==='RenewLicenseDisk','byrole': !ISINTERNALSYSTEMUSER}">
                <router-link to="/OnlineServices/RenewLicenseDisk">Renew License Disk</router-link>
              </li>
              <li data-role="OLE" :class="{'active hide': ActiveMenu==='RenewOperatorLicense', 'byrole': !ISINTERNALSYSTEMUSER}">
                <router-link to="/OnlineServices/RenewOperatorLicense">Operating License</router-link>
              </li>
              <li data-role="PPC" :class="{'active hide': ActiveMenu==='prepaidpetrolcard', 'byrole': !ISINTERNALSYSTEMUSER}">
                <router-link to="/OnlineServices/prepaidpetrolcard">Prepaid Petrol Card</router-link>
              </li>
              <li data-role="PEG" :class="{'active': ActiveMenu==='PrepaideTag', 'byrole': !ISINTERNALSYSTEMUSER}">
                <router-link to="/OnlineServices/PrepaideTag">Prepaid eTag</router-link>
              </li>
              <li data-role="CRI" :class="{'active': ActiveMenu==='CreditRepair', 'byrole': !ISINTERNALSYSTEMUSER}">
                <router-link to="/OnlineServices/CreditRepair">Credit Repair</router-link>
              </li>
              <li data-role="MLN" :class="{'active': ActiveMenu==='ShortTermLoan', 'byrole': !ISINTERNALSYSTEMUSER}">
                <router-link to="/OnlineServices/ShortTermLoan">Micro Loan</router-link>
              </li>
            </ul>
          </li>

          <li
            :class="['has-sub',{'active': ActiveSubMenu==='FleetManagement','byrole': !ISINTERNALSYSTEMUSER}]" data-role="FAN"
          >
            <a href="#">
              <b class="caret"></b>
              <i class="fas fa-location-arrow bg-gradient-orange"></i>
              <span>{{USERPROFILE.taxi_associations_abbr + ' Tracker'}}</span>
              <!-- <span>Fleet Management</span> -->
            </a>
            <ul class="sub-menu">
              <li  data-role="FAN" :class="[{'active' :ActiveMenu==='Dashboard','byrole': !ISINTERNALSYSTEMUSER}]">
                <router-link to="/FleetManagment/Dashboard">Dashboard</router-link>
              </li>
              <li :class="{'active': ActiveMenu==='AddClient'}">
                <router-link to="/FleetManagment/AddClient/:id">New Application</router-link>
              </li>
              <li :class="{'active': ActiveMenu==='Bookings'}">
                <router-link to="/FleetManagment/Bookings">Bookings</router-link>
              </li>
              <li :class="{'active': ActiveMenu==='ListOfClients'}">
                <router-link to="/FleetManagment/ListOfClients">Clients</router-link>
              </li>
              <li :class="{'active': ActiveMenu==='Arrears'}">
                <router-link to="/FleetManagment/Arrears">Arrears</router-link>
              </li>
              <li :class="{'active': ActiveMenu==='Inventory'}">
                <router-link to="/FleetManagment/Inventory">Inventory</router-link>
              </li>
            </ul>
          </li>

          <li
            :class="['has-sub',{'active': ActiveSubMenu==='LoanApplication','byrole': !ISINTERNALSYSTEMUSER}]" data-role="LAN"
          >
            <a href="#">
              <b class="caret"></b>
              <i class="fab fa-amazon-pay bg-gradient-orange"></i>
              <span>{{USERPROFILE.taxi_associations_abbr + ' Loans'}}</span>
              <!-- <span>Fleet Management</span> -->
            </a>
            <ul class="sub-menu">
              <li  data-role="LAN" :class="[{'active' :ActiveMenu==='Dashboard','byrole': !ISINTERNALSYSTEMUSER}]">
                <router-link to="/LoanApplication/DashBoard">Dashboard</router-link>
              </li>
              <li :class="{'active': ActiveMenu==='NewLoanApplication'}">
                <router-link to="/LoanApplication/New/:id">New Application</router-link>
              </li>
              <li :class="{'active': ActiveMenu==='Loans'}">
                <router-link to="/LoanApplication/Loans">Loans</router-link>
              </li>
            </ul>
          </li>
          
          <li
            class="has-sub"
            :class="{'active': ActiveSubMenu==='Emails'}"
            
          >
            <a href="javascript:;">
              <span class="badge pull-right">{{GETTOTALUNREAD}}</span>
              <i class="ion-ios-mail"></i>
              <span>Messages</span>
            </a>
            <ul class="sub-menu">
              <li :class="{'active' :ActiveMenu==='Inbox'}">
                <router-link :to="{ name: 'inbox', params: {id: USERID } }">Inbox</router-link>
              </li>
              <li :class="{'active' :ActiveMenu==='Compose'}">
                <router-link to="/Home/Compose">Compose</router-link>
              </li>
              <!-- <li>
                <a href="email_detail.html">Detail</a>
              </li> -->
            </ul>
          </li>
          <li class="has-sub" v-if="ISINTERNALSYSTEMUSER" :class="{'active': ActiveSubMenu==='QuickSetup'}">
            <a href="#">
              <b class="caret"></b>
              <i class="ion-ios-cog bg-gradient-purple"></i>
              <span>Guest CheckIn</span>
            </a>
            <ul class="sub-menu">
              
               <li 
               v-if="USERGROUPCODE==='CHM' || USERGROUPCODE==='SGL' || USERGROUPCODE==='TRS' || ISINTERNALSYSTEMUSER"
               :class="{'active':ActiveMenu==='AddSystemUser'}">
                <router-link to="/QuickSetup/checkinguests">CheckIn Guest</router-link>
              </li>

            </ul>
          </li>
          <li class="has-sub" :class="{'active': ActiveSubMenu==='QuickSetup'}">
            <a href="#">
              <b class="caret"></b>
              <i class="ion-ios-cog bg-gradient-purple"></i>
              <span>Quick Action</span>
            </a>
            <ul class="sub-menu">
              
              <li 
               v-if="USERGROUPCODE==='SGL'"
               :class="{'active':ActiveMenu==='LetterOfGoodStanding'}">
                <router-link to="/TaxiAssociation/GoodStanding">Letter Of Good Standing</router-link>
              </li>

               <li 
               v-if="ISINTERNALSYSTEMUSER"
               :class="{'active':ActiveMenu==='AddSystemUser'}">
                <router-link to="/QuickSetup/AddSystemUser">Add System Users</router-link>
              </li>
              <li 
               v-if="USERGROUPCODE==='CHM' || USERGROUPCODE==='SGL' || USERGROUPCODE==='TRS'"
               :class="{'active':ActiveMenu==='LetterOfGoodStanding'}">
               <router-link to="/Secretary/ManageRoute">Manage Routes</router-link>
              </li>
               <li 
               v-if="USERGROUPCODE==='CHM' || USERGROUPCODE==='SGL' || USERGROUPCODE==='TRS' || ISINTERNALSYSTEMUSER"
               :class="{'active':ActiveMenu==='LiveTrack'}">
                <router-link to="/QuickSetup/LiveTrack">Live Track</router-link>
              </li>

            </ul>
          </li>
          <li class="has-sub" :class="{'active':ActiveSubMenu==='Approvals'}">
            <a href="#">
              <b class="caret"></b>
              <i class="ion-md-git-network bg-gradient-green"></i>
              <span>Approvals</span>
            </a>
            <ul class="sub-menu">
              <li :class="{'active' :ActiveMenu==='Authorise'}">
                <router-link :to="{ name: 'approvals', params: { id: USERID } }">Authorisation</router-link>
              </li>
              
              <li :class="{'active' :ActiveMenu==='DataCollection'}">
                <router-link :to="{ name: 'manageapprovals', params: { id: USERID } }">Data Captured</router-link>
              </li>
            </ul>
          </li>
          <!-- <li
            class="has-sub"
            v-if="USERGROUPCODE==='OCK' || USERGROUPCODE==='TRS' || USERGROUPCODE==='CHM' || ISINTERNALSYSTEMUSER || USERGROUPCODE !== 'DCL'"
            :class="{'active': ActiveSubMenu==='FuneralScheme'}"
          >
            <a href="#">
              <b class="caret"></b>
              <i class="fas fa-dollar-sign bg-purple"></i>
              <span>Funeral Scheme</span>
            </a>
            <ul class="sub-menu">
              <li :class="{'active' :ActiveMenu==='TakeVIPPayment'}">
                <router-link to="/money/takepayment">Take Payment</router-link>
              </li>
              <li :class="{'active': ActiveMenu==='CreateInvoice'}">
                <router-link to="/money/createinvoice">Create Invoice</router-link>
              </li>
              <li :class="{'active': ActiveMenu==='Statement'}">
                <router-link to="/money/statement">View Statement</router-link>
              </li>
              <li :class="{'active': ActiveMenu==='FuneralSchemeCollection'}">
                <router-link to="/money/FuneralSchemeCollection">Collection Report</router-link>
              </li>
            </ul>
          </li> -->
          <li
           data-role="RVC"
            :class="['has-sub',{'byrole': !ISINTERNALSYSTEMUSER },{'active': ActiveSubMenu==='Money'}]"
          >
            <a href="#">
              <b class="caret"></b>
              <i class="fas fa-dollar-sign bg-gradient-green"></i>
              <span>Money</span>
            </a>
            <ul class="sub-menu">
              <li :class="{'active' :ActiveMenu==='TakePayment'}">
                <router-link to="/money/takepayment">Take Payment</router-link>
              </li>
              <!-- <li :class="{'active' :ActiveMenu==='TakeBulkPayment'}">
                <router-link to="/money/takeBulkPayment">Upload Bulk Payment</router-link>
              </li> -->

              <li :class="{'active' :ActiveMenu==='TakeManualPayment'}">
                <router-link to="/money/manualBulkPayment">Manual Bulk Payment</router-link>
              </li>
              <li :class="{'active' :ActiveMenu==='RefundPayment'}">
                <router-link to="/money/refundpayment">Refund Payment</router-link>
              </li>
              <!-- <li :class="{'active' :ActiveMenu==='SearchPayment'}">
                <router-link to="/money/payment/search">Search For Payment</router-link>
              </li>-->
              <li :class="{'active': ActiveMenu==='CreateInvoice'}">
                <router-link to="/money/createinvoice">Create Invoice</router-link>
              </li>
              <!-- <li :class="{'active': ActiveMenu==='SearchInvoice'}">
                <router-link to="//money/invoice/search">Search For Invoice</router-link>
              </li>-->
              <li :class="{'active': ActiveMenu==='Statement'}">
                <router-link to="/money/statement">View Statement</router-link>
              </li>
              <li :class="{'active': ActiveMenu==='Cashup'}">
                <router-link to="/money/cashup">Cashup</router-link>
              </li>
              <li :class="{'active': ActiveMenu==='collectionreport'}">
                <router-link to="/money/collectionreport">Collection Report</router-link>
              </li>
            </ul>
          </li>
            <li data-role="VVP" :class="['has-sub',{'byrole': !ISINTERNALSYSTEMUSER },{'active': ActiveSubMenu==='TaxiVIP'}]">
            <a href="javascript:;">
              <b class="caret"></b>
              <i class="fas fa-gem bg-yellow"></i>
              <span>TAMS VIP</span>
            </a>
            <ul class="sub-menu">
              <li :class="{'active':ActiveMenu ==='VIPDashboard'}">
                <router-link to="/TaxiVIP/Dashboard">DashBoard</router-link>
              </li>
              <li :class="{'active':ActiveMenu ==='ViewVIPCommuters'}">
                <router-link to="/TaxiVIP/Commuters">View Commuters</router-link>
              </li>
              <li :class="{'active':ActiveMenu ==='ManageVIPCards'}">
                <router-link to="/TaxiVIP/VIPCards">Manage VIP Cards</router-link>
              </li>
              <li :class="{'active':ActiveMenu ==='ManageVIPRoutes'}">
                <router-link to="/TaxiVIP/ManageVIPRoutes">View VIP Routes</router-link>
              </li>
            
            </ul>
          </li>
          <li data-role="RTO" :class="['has-sub',{'byrole': !ISINTERNALSYSTEMUSER },{'active': ActiveSubMenu==='Operators'}]">
            <a href="javascript:;">
              <b class="caret"></b>
              <i class="ion-ios-contacts bg-blue"></i>
              <span>Operators</span>
            </a>
            <ul class="sub-menu">
              <li data-role="ITO" :class="[{'byrole': !ISINTERNALSYSTEMUSER},{'active' :ActiveMenu==='AddTaxiOperator'}]">
                <router-link to="/Operators/New/:id">Add Taxi Operator</router-link>
              </li>
              <!-- <li>
                <router-link to="/Operator/Profile">Profile</router-link>
              </li> -->
              <li :class="{'active': ActiveMenu==='Search'}">
                <router-link to="/Operators/Search">Search</router-link>
              </li>
              <li :class="{'active': ActiveMenu==='List'}">
                <router-link to="/Operators/List">List</router-link>
               </li>
                <li 
               :class="{'active':ActiveMenu==='BulkDocumentsUpload'}">
                <router-link to="/Operators/BulkUpload">Upload Documents</router-link>
              </li>
               <li :class="{'active': ActiveMenu==='Verify'}">
                <router-link to="/Operators/Verify">Verification</router-link>
               </li>
            </ul>
          </li>
          <li data-role="RTV" :class="['has-sub',{'byrole': !ISINTERNALSYSTEMUSER },{'active': ActiveSubMenu==='ManageVehicle'}]">
            <a href="javascript:;">
              <b class="caret"></b>
              <i class="fas fa-car bg-aqua"></i>
              <span>Taxis</span>
            </a>
            <ul class="sub-menu">
              <li :class="[{'byrole': !ISINTERNALSYSTEMUSER},{'active':ActiveMenu==='AddTaxi'}]" data-role="ITV">
                <router-link to="/Vehicle/Add/:id">Add Taxi Vehicle</router-link>
              </li>
              
              <li :class="{'active': ActiveMenu==='dataCollection'}">
                <router-link to="/Vehicles/DataCollection">Data Collection</router-link>
              </li>
               <li :class="{'active': ActiveMenu==='Search'}">
                <router-link to="/Vehicles/Search">Search</router-link>
              </li>

              <li :class="{'active': ActiveMenu==='Taxi Operator Vehicles'}">
                <router-link to="/Vehicles/List">List</router-link>
              </li>
              <li :class="{'active': ActiveMenu==='Expired Operating License'}">
                <router-link to="/Vehicles/ExpiredOperatingLicense">Expired Licenses</router-link>
              </li>
              <li :class="{'active': ActiveMenu==='Collector Vehicles'}">
                <router-link to="/Vehicles/collectorvehicles">Collector Vehicles</router-link>
              </li>
               <li 
               :class="{'active':ActiveMenu==='BulkDocumentsUpload'}">
                <router-link to="/Vehicles/BulkUpload">Upload Documents</router-link>
              </li>
              <li v-if="ISINTERNALSYSTEMUSER" :class="{'active': ActiveMenu==='ManageMakes'}">
                <router-link to="/Vehicles/Makes">Makes</router-link>
              </li>
              <li :class="{'active': ActiveMenu==='Taxi Vehicle Impounds'}">
                <router-link to="/Vehicles/Impound">Vehicle Impounds</router-link>
              </li>
            </ul>
          </li>
          <li data-role="RTD" :class="['has-sub',{'byrole': !ISINTERNALSYSTEMUSER },{'active': ActiveSubMenu==='ManageDrivers'}]">
            <a href="javascript:;">
              <b class="caret"></b>
              <i class="ion-ios-man bg-orange"></i>
              <span>Drivers</span>
            </a>
            <ul class="sub-menu">
              <li :class="['has-sub',{'byrole': !ISINTERNALSYSTEMUSER},{'active':ActiveMenu==='AddTaxiDriver'}]" data-role="ITD" >
                <router-link to="/QuickSetup/Driver/:id">Add Taxi Driver</router-link>
              </li>
              <li :class="{'active':ActiveMenu==='Search'}">
                <router-link :to="{ path: '/Driver/Search' }">Search</router-link>
              </li>
              <li :class="{'active':ActiveMenu==='List'}">
                <router-link :to="{ path: '/Driver/All' }">List</router-link>
              </li>
               <li 
               :class="{'active':ActiveMenu==='BulkDocumentsUpload'}">
                <router-link to="/Driver/BulkUpload">Upload Documents</router-link>
              </li>
              <li  :class="{'active':ActiveMenu==='Verify'}">
                <router-link :to="{ path: '/Driver/Verification' }">Verification</router-link>
              </li>
            </ul>
          </li>

          <li class="has-sub" :class="{'active': ActiveSubMenu==='Chairman'}"  v-if="USERGROUPCODE==='CHM' || USERGROUPCODE==='ISA'" >
            <a href="javascript:;">
              <b class="caret"></b>
              <i class="ion-md-person bg-pink"></i>
              <span>Chairman</span>
            </a>
            <ul class="sub-menu">
              <li
                :class="{'active' :ActiveMenu==='LetterOfGoodStanding'}"
              >
                <router-link to="/TaxiAssociation/GoodStanding">Letter Of Good Standing</router-link>
              </li>
            </ul>
          </li>

           

          <!-- <li class="has-sub" v-if="USERGROUPCODE==='SGL' || USERGROUPCODE==='ISA'">
            <a href="javascript:;">
              <b class="caret"></b>
              <i class="ion-md-microphone bg-yellow"></i>
              <span>Secretary</span>
            </a>
            <ul class="sub-menu">
              <li :class="{'active':ActiveMenu ==='List'}">
                <router-link to="/Operators/List">Taxi Operator Profile</router-link>
              </li>
              <li>
                <a href="Taxi_Operator.html">Taxi Operator</a>
              </li>
            </ul>
          </li> -->
          <!-- <li class="has-sub" v-if="USERGROUPCODE==='TRS' || USERGROUPCODE==='ISA'">
            <a href="javascript:;">
              <b class="caret"></b>
              <i class="ion-md-briefcase bg-pink"></i>
              <span>Treasurer</span>
            </a>
            <ul class="sub-menu">
              <li :class="{'active':ActiveMenu ==='List'}">
                <router-link to="/Operators/List">Taxi Operator Profile</router-link>
              </li>
            </ul>
          </li> -->
          <!-- <li v-if="USERGROUPCODE==='GMG' || USERGROUPCODE==='ISA'" :class="['has-sub',{'active': ActiveSubMenu==='Compliance'}]">
            <a href="javascript:;">
              <b class="caret"></b>
              <i class="ion-ios-hand bg-grey"></i>
              <span>Compliance</span>
            </a>
            <ul class="sub-menu">
              <li>
                <a href="Taxi_Operator.html">Taxi Operator</a>
              </li>
              <li>
                <a href="Taxi_Operatorcontacts.html">Taxi Operator Contacts</a>
              </li>
              <li>
                <a href="Taxi_Operatorstatus.html">Taxi Operator Status</a>
              </li>
              <li>
                <a href="Supporting_Documents.html">Supporting Documents</a>
              </li>
            </ul>
          </li> -->
          <li class="has-sub" v-if="USERGROUPCODE==='OCK' ||  USERGROUPCODE==='SGL' ||  USERGROUPCODE==='CHM' ||  USERGROUPCODE==='CMR' || USERGROUPCODE==='ISA'"  :class="{'active': ActiveSubMenu==='Office'}">
            <a href="javascript:;">
              <b class="caret"></b>
              <i class="ion-md-laptop bg-pink"></i>
              <span>Office</span>
            </a>
            <ul class="sub-menu">
               <li :class="{'active':ActiveMenu ==='Calendar'}">
                <router-link to="/Office/Calendar">Calendar</router-link>
                 </li>
                 <li
                :class="{'active' :ActiveMenu==='LetterOfGoodStanding'}"
              >
                <router-link to="/TaxiAssociation/GoodStanding">Letter Of Good Standing</router-link>
              </li>
               <li :class="{'active':ActiveMenu ==='SendSMS'}">
                <router-link to="/Office/SendBulkSMS">Send Bulk SMS</router-link>
              </li>

              <!-- <li :class="{'active':ActiveMenu ==='RealtimeMonitor'}">
                <router-link to="/Office/RealTimeMonitor">Realtime Monitor</router-link>
              </li> -->
              
            </ul>
          </li>
          <!-- <li class="has-sub" v-if="USERGROUPCODE==='ISA'" :class="{'active':ActiveSubMenu==='configuration'}" >
            <a href="javascript:;">
              <b class="caret"></b>
              <i class="ion-ios-cog bg-gradient-purple"></i>
              <span>configuration</span>
            </a>
            <ul class="sub-menu">
              <li>
                <a href="Device_Type.html">Device Type</a>
              </li>
              <li>
                <a href="Front_End.html">Front End</a>
              </li>
              <li>
                <a href="Front_End_Controls.html">Front End Controls</a>
              </li>
            </ul>
          </li> -->
          <li v-if="isSystemAdmin" v-show="USERGROUPCODE==='ISA'" :class="['has-sub',{'active':ActiveSubMenu==='Geographic'}]"
          >
            <a href="javascript:;">
              <b class="caret"></b>
              <i class="fas fa-map-marker-alt bg-green"></i>
              <span>Geographic</span>
            </a>
            <ul class="sub-menu">
              <li>
                <router-link to="/SysAdmin/country">Country</router-link>
              </li>
              <!-- <li>
                <a href="Foreign_Country.html">Foreign Country</a>
              </li> -->
              <li>
                <router-link to="/SysAdmin/province">Provinces</router-link>
              </li>
              <li :class="{'active':ActiveMenu ==='AddCity'}">
                <router-link to="/SysAdmin/city">City</router-link>
              </li>
              <li v-show="USERPROFILE.is_system_admin">
                <router-link to="/SysAdmin/suburb">Suburb</router-link>
              </li>
              <li v-show="USERPROFILE.is_system_admin">
                <router-link to="/SysAdmin/District">District</router-link>
              </li>
            </ul>
          </li>
          <li
            class="has-sub"
            v-if="USERGROUPCODE==='ISA' || USERGROUPCODE==='CHM'"
            :class="{'active': ActiveSubMenu==='ManageUserInfo'}"
          >
            <a href="javascript:;">
              <b class="caret"></b>
              <i class="ion-ios-people bg-gradient-aqua"></i>
              <span>User Settings</span>
            </a>
            <ul class="sub-menu">
              
              <!-- <li>
                <a href="Role_Default.html">Role Default</a>
              </li>
              <li>
                <a href="Roles.html">Roles</a>
              </li>
              <li>
                <a href="User_Group_Default.html">User Group Default</a>
              </li> -->
              <li v-if="ISINTERNALSYSTEMUSER" :class="{'active':ActiveMenu==='userGroups'}">
                <router-link to="/SysAdmin/UserGroups">User Group</router-link>
              </li>
              <!-- <li>
                <a href="Role_User_Group.html">Role user Group</a>
              </li> -->
              <li v-if="ISINTERNALSYSTEMUSER" :class="{'active':ActiveMenu==='Titles'}">
                <router-link to="/SysAdmin/Titles">Title</router-link>
              </li>
              <li v-if="ISINTERNALSYSTEMUSER" :class="{'active':ActiveMenu==='Gender'}">
                <router-link to="/SysAdmin/Gender">Gender</router-link>
              </li>
              <li :class="{'active':ActiveMenu==='SystemUsers'}">
                <router-link to="/SysAdmin/SystemUsers">System Users</router-link>
              </li>
              <!-- <li v-if="ISINTERNALSYSTEMUSER" :class="{'active':ActiveMenu==='SystemUsers'}">
                <router-link to="/SysAdmin/OtherSystemUsers">Other System Users</router-link>
              </li> -->
            </ul>
          </li>
          <!-- <li class="has-sub" v-if="USERGROUPCODE==='ISA'">
            <a href="javascript:;">
              <b class="caret"></b>
              <i class="fas fa-exclamation-triangle bg-gradient-blue"></i>
              <span>Audits</span>
            </a>
            <ul class="sub-menu">
              <li>
                <a href="Errorlog.html">Error Log</a>
              </li>
              <li>
                <a href="Login_Session.html">Login Session</a>
              </li>
              <li>
                <a href="Taxi_Association_Log.html">Taxi Association Log</a>
              </li>
              <li>
                <a href="Taxi_Operatorslog.html">Taxi Operators log</a>
              </li>
              <li>
                <a href="Taxi_Associations_With_Taxi_Operators_Log.html">
                  Taxi Associations With Taxi
                  Operators Log
                </a>
              </li>
              <li>
                <a href="Taxi_Operator_Vehicle_Log.html">Taxi Operator Vehicle Log</a>
              </li>
              <li>
                <a href="Taxi_Driverslog.html">Taxi Driver Log</a>
              </li>
              <li>
                <a href="Invoiceslog.html">Invoices Log</a>
              </li>
              <li>
                <a href="Receiptslog.html">Receipts Log</a>
              </li>
            </ul>
          </li> -->
          <!-- <li class="has-sub" v-if="USERGROUPCODE==='ISA'">
            <a href="javascript:;">
              <b class="caret"></b>
              <i class="fas fa-taxi bg-blue"></i>
              <span>Vehicle Settings</span>
            </a>
            <ul class="sub-menu">
              <li>
                <a href="Vehicle_make.html">Vehicle Make</a>
              </li>
              <li>
                <a href="Year_Model.html">Year Model</a>
              </li>
              <li>
                <a href="Vehicle_Model.html">Vehicle Model</a>
              </li>
            </ul>
          </li> -->

          <!-- <li class="has-sub" v-if="USERGROUPCODE==='ISA'">
            <a href="javascript:;">
              <b class="caret"></b>
              <i class="fas fa-dollar-sign bg-purple"></i>
              <span>Finance</span>
            </a>
            <ul class="sub-menu">
              <li>
                <a href="Billing_Exclusions.html">Billing Exclusions</a>
              </li>
              <li>
                <a href="Billing_Items.html">Billing Items</a>
              </li>
              <li>
                <a href="Billing_Type.html">Billing Type</a>
              </li>
              <li>
                <a href="Penalty_Fees.html">Penalty Fees</a>
              </li>
              <li>
                <a href="Collection_Frequency.html">Collection Frequency</a>
              </li>
              <li>
                <a href="Collection_Settings/Type.html">Collection Settings/Type</a>
              </li>
              <li>
                <a href="Dispute_Status.html">Dispute Status</a>
              </li>
              <li>
                <a href="Receipt_Status.html">Receipt Status</a>
              </li>
              <li>
                <a href="Invoice_Disputes_with_Feedback.html">Invoice Disputes with Feedback</a>
              </li>
              <li>
                <a href="Invoices.html">Invoices</a>
              </li>
              <li>
                <a href="Receipts.html">Receipts</a>
              </li>
              <li>
                <a href="Invoice_Receipts_Print.html">Invoice Receipts Print</a>
              </li>
              <li>
                <a href="Invoice_Status.html">Invoice Status</a>
              </li>
              <li>
                <a href="Type_of_Billing_Client.html">Type of Billing Client</a>
              </li>
            </ul>
          </li> -->
          <!-- <li class="has-sub" v-if="USERGROUPCODE==='GOF'">
            <a href="javascript:;">
              <b class="caret"></b>
              <i class="ion-ios-flower bg-indigo"></i>
              <span>Compliance</span>
            </a>
            <ul class="sub-menu">
              <li>
                <a href="Breathelyzer_Test.html">Breathelyzer Test</a>
              </li>
              <li>
                <a href="Media_Type.html">Media Type</a>
              </li>
              <li>
                <a href="Recorded_Notes.html">Recorded Notes</a>
              </li>
              <li>
                <a href="Compliance_Violation_Settings.html">Compliance Violation Settings</a>
              </li>
              <li>
                <a href="Compliance_Violation_Settings_Default.html">
                  Compliance Violation Settings
                  Default
                </a>
              </li>
            </ul>
          </li> -->
          <!-- begin sidebar minify button -->
          <li class="hide">
            <a href="javascript:;" class="sidebar-minify-btn" data-click="sidebar-minify">
              <i class="ion-ios-arrow-back"></i>
              <span>Collapse</span>
            </a>
          </li>
          <!-- end sidebar minify button -->
          <!-- end sidebar minify button -->
        </ul>
        <!-- end sidebar nav -->
      </div>
      <!-- end sidebar scrollbar -->
    </div>

    <div class="sidebar-bg"></div>
    <!-- end #sidebar -->
  </div>
</template>
    
<script>
import navprofileComp from "@/components/common/navprofile.vue";

import { createNamespacedHelpers } from "vuex";
const { mapGetters, mapActions } = createNamespacedHelpers("auth");
const config = require('../../configurations/configbase');

export default {
  name: "sidebar",
  props: {
    USERPROFILE: {
      type: Object,
      required: true
    },
    ActiveSubMenu: {
      type: String,
      required: true
    },
    ActiveMenu: {
      type: String,
      required: true
    },
    USERROLES: {
      type: Array,
      required: false
    }
  },
  data: function() {
    return {
      message: "Some Message",
      isSystemAdmin: this.$store.getters["auth/USERPROFILE"].is_system_admin,
      USERID: this.$store.getters["auth/USERPROFILE"].system_usersid,
      USERGROUPCODE: this.$store.getters["auth/USERPROFILE"].usergroups_code,
     //USERPROFILE: this.$store.getters["auth/USERPROFILE"],
      taxiassociationid: this.$store.getters["auth/ASSIGNEDTAXIASSOCIATION"].id,
      ISTPAYAGENT: this.$store.getters["auth/ISTPAYAGENT"],
      context: this
    };
  },
  components: {
    navprofileComp
  },
  computed: {
    ...mapGetters(["GETUSERROLES", "ISINTERNALSYSTEMUSER", "GETTOTALUNREAD"])
  },
  beforeMount() {
    
  },
  methods: {
    FILTERBYROLE: context => {
      $(".byrole").each(function() {
        if (
          context.USERROLES.length > 0 &&
          context.USERROLES.filter(
            item => item._code === $(this).attr("data-role")
          ).length > 0
        )
          $(this).removeClass('hide');
      });
    },
    FILTERBYUSERGROUP: _code => {
      const UserGroupFound = _code.filter(item => item == USERGROUPCODE);
      UserGroupFound;
      return UserGroupFound.length > 0;
    }
  },
  mounted() {
    
     $(".byrole").addClass('hide');
    this.FILTERBYROLE(this.context);
    
    this.context.$store.dispatch('messageinbox/gettotalunreadmessage', {
                      router: this.context.$router,
                      id: this.context.USERID
                    }).then((_totalunread) => {

          window.PushManager.listenToIncomingMessages(this.context);
      });

      

    Cohere.init("OjkGgmEmwWrccuIGR9eL_YU6",{
      disableRecording: true
    });

    //

     Cohere.identify(
            this.USERPROFILE.system_usersid, // Required: can be any unique ID
            {
              displayName: this.USERPROFILE.first_name + " " + this.USERPROFILE.surname, 
            },
          );

    Cohere.widget("show");
      
   
    
    //RealtimeWebManager.init(config.default.BASESOCKET_URL(),this.$store.getters["auth/USERPROFILE"].token,this.context.USERID);

    //window.RealtimeWeb.emit('rankmonitor', '1'/*Rank Id*/); 
      // window.RealtimeWeb.on('rankmonitorIn', (data) => {
      //     
      //   console.log(data);
      // });

      //  window.RealtimeWeb.on('rankmonitorOut', (data) => {
      //     
      //   console.log(data);
      // });

      //  window.RealtimeWeb.on('rankmonitorInOut', (data) => {
      //     
      //   console.log(data);
      // });

  }
};
</script>

<style scoped>
@import "../css/bounce-animation.css";
</style>