  <template>
  <div class="home">
    <!-- begin #page-container -->
    <!-- begin #header -->
    <headerComp
      :AddTaxiOperatorDropDownControl="true"
      :AddTopSearchControl="true"
      :AddTopNotificationControl="true"
      :isAdminUser="USERPROFILE.is_system_admin"
    />
    <!-- begin #header -->
    <!-- end page container -->

    <!-- begin #sidebar -->
    <sidebarComp
      :USERROLES="ROLES"
      :USERPROFILE="USERPROFILE"
      :ActiveSubMenu="'LoanApplication'"
      :ActiveMenu="'NewLoanApplication'"
    />
    <!-- end #sidebar -->

    <!-- begin #content -->
    <div id="content" class="content">
      <!-- begin page-header -->
      <headerpagebreadcrumb
        pageheaderTitle="New Loan Application"
        pageheaderdescription
        breadcrumbitem1="Home"
        breadcrumbitem2="Loan Application"
        breadcrumbitem3="New"
      />
      <!-- end page-header -->

      <!-- begin #Main Body Template -->

           <!-- begin nav-pills -->
           <ul class="nav nav-pills">
        <li class="nav-items">
          <a href="#nav-pills-tab-1" data-toggle="tab" class="nav-link active nav-pills-tab-1">
            <span class="d-sm-none">Add Application</span>
            <span class="d-sm-block d-none">Add Application</span>
          </a>
        </li>
        <li class="nav-items">
          <a href="#nav-pills-tab-2" data-toggle="tab" class="nav-link">
            <span class="d-sm-none">View Pending Booking</span>
            <span class="d-sm-block d-none">View Pending Booking</span>
          </a>
        </li>
      </ul>
      <!-- end nav-pills -->

       <!-- begin tab-content -->
       <div class="tab-content">

<div class="tab-pane fade active show" id="nav-pills-tab-1">
        <!-- begin #Main Body Template -->

        <div class="card text-center">
        <div
          v-if="isIdNumberValid == undefined"
          class="alert alert-primary fade show"
        >WAITING TO VERIFY ID NUMBER</div>
      </div>

      <div class="card text-center">
        <div
          v-if="!isIdNumberValid && isIdNumberValid != undefined "
          class="alert alert-danger fade show"
        >INVALID ID NUMBER!</div>
      </div>

      <div class="card text-center">
        <div v-if="isIdNumberValid" class="alert alert-success fade show">ID NUMBER VERFIED</div>
      </div>

      <div class="row">
        <!-- begin col-12 -->
        <div class="col-lg-12">
          <!-- begin wizard-form -->
          <form action="/" method="POST" name="form-wizard" class="form-control-with-bg">
            <!-- begin wizard -->
            <div id="wizard">
              <!-- begin wizard-step -->
              <ul>
                <li class="col-md-3 col-sm-4 col-6">
                  <a href="#step-1">
                    <span class="number">1</span>
                    <span class="info text-ellipsis">
                      Applicant Information
                      <small class="text-ellipsis">Name, Address, ID No and DOB</small>
                    </span>
                  </a>
                </li>
                <li class="col-md-3 col-sm-4 col-6">
                  <a href="#step-2">
                    <span class="number">2</span>
                    <span class="info text-ellipsis">
                      Supporting Documents
                      <small class="text-ellipsis">Proof Of ID, Address And Bank Account</small>
                    </span>
                  </a>
                </li>
                <li class="col-md-3 col-sm-4 col-6">
                  <a href="#step-3">
                    <span class="number">3</span>
                    <span class="info text-ellipsis">
                      Completed
                      <small class="text-ellipsis">Complete Registration</small>
                    </span>
                  </a>
                </li>
              </ul>
              <!-- end wizard-step -->
              <!-- begin wizard-content -->
              <div>
                <!-- begin step-1 -->
                <div id="step-1">
                  <!-- begin fieldset -->
                  <!-- begin row -->
                  <div class="row">
                    <!-- begin col-8 -->

                    <div class="col-md-12">
                      <div class="row panel panel-primary">
                        <!-- begin col-8 -->
                        <div class="col-lg-6">
                          <!-- end form-group -->
                          <div class="form-group row m-b-10">
                            <label class="col-md-3 col-form-label text-md-right">Select Operator</label>
                            <span class="text-danger"><i v-show="GETOPERATORS.length===0" class="fas fa-spinner fa-spin"></i></span>
                            <div class="col-md-6">
                              <div class="input-group">
                                <select
                              class="form-control"
                              id="selectoperatorpicker"
                              data-size="10"
                              data-live-search="true"
                              data-parsley-required="false"
                            >
                              <option value="0" selected>Select Operator</option>
                              <option
                                :key="item.taxi_operator_id"
                                v-for="item in GETOPERATORS"
                                :value="item.taxi_operator_id"
                              >
                                {{
                                item.firstname +
                                " " +
                                item.surname +
                                "(" +
                                item.id_number +
                                ")"
                                }}
                              </option>
                            </select>
                              </div>
                            </div>
                          </div>
                          <!-- end form-group -->
                         
                          <br />
                           <!-- begin form-group -->
                           <div class="form-group row m-b-10">
                            <label class="col-md-3 col-form-label text-md-right">ID Number</label>
                            <span class="text-danger">*</span>
                            <div class="col-md-6">
                              <input
                                type="text"
                                data-parsley-required="true"
                                data-parsley-maxlength="13"
                                id="usernameId"
                                placeholder="Enter ID Number"
                                :class="['form-control',{'is-invalid': !isIdNumberValid , 'is-valid': isIdNumberValid  }]"
                                v-model.lazy="IDNUMBER"
                              />
                            </div>
                          </div>
                          <!-- end form-group -->
                           <!-- begin form-group -->
                           <div class="form-group row m-b-10">
                            <label class="col-md-3 col-form-label text-md-right">
                              First Name
                              <span class="text-danger">*</span>
                            </label>
                            <div class="col-md-6">
                              <input
                                type="text"
                                id="firstname"
                                name="firstname"
                                data-parsley-group="step-1"
                                data-live-search="true"
                                data-parsley-required="true"
                                class="form-control"
                                v-model="ADDOPERATOR.firstname"
                              />
                            </div>
                          </div>
                          <!-- end form-group -->
                           <!-- begin form-group -->
                           <div class="form-group row m-b-10">
                            <label class="col-md-3 col-form-label text-md-right">
                              Surname
                              <span class="text-danger">*</span>
                            </label>
                            <div class="col-md-6">
                              <input
                                type="text"
                                name="surname"
                                data-parsley-group="step-1"
                                data-live-search="true"
                                data-parsley-required="true"
                                class="form-control"
                                v-model="ADDOPERATOR.surname"
                              />
                            </div>
                          </div>
                           <!-- end form-group -->

                         
                              <!-- begin form-group -->
                              <div class="form-group row m-b-10">
                            <label class="col-md-3 col-form-label text-md-right">Mobile Number
                              <span class="text-danger">*</span>
                            </label>
                            <div class="col-md-6">
                              <input
                                type="number"
                                data-parsley-group="step-1"
                                data-parsley-required="true"
                                class="form-control"
                                v-model="ADDOPERATOR.mobilenumber"
                              />
                            </div>
                          </div>
                          <!-- end form-group -->

                             <!-- begin form-group -->
                          <div class="form-group row m-b-10">
                            <label class="col-md-3 col-form-label text-md-right">Passport Number</label>
                            <div class="col-md-6">
                              <input
                                type="text"
                                data-parsley-group="step-1"
                                data-parsley-required="false"
                                class="form-control"
                                v-model="ADDOPERATOR.passport_number"
                              />
                            </div>
                          </div>
                          <!-- end form-group -->

                            <!-- begin form-group -->
                            <div class="form-group row m-b-10">
                            <label class="col-md-3 col-form-label text-md-right">Physical Address</label>
                            <span class="text-danger">*</span>
                            <div class="col-md-6">
                              <input
                                type="text"
                                data-parsley-group="step-1"
                                
                                data-parsley-required="true"
                                class="form-control"
                                v-model="ADDOPERATOR.physical_address"
                              />
                            </div>
                          </div>
                          <!-- end form-group -->

                          <!-- begin form-group -->
                          <div class="form-group row m-b-10">
                            <label class="col-md-3 col-form-label text-md-right">Physical Address 2</label>
                            <span class="text-danger">*</span>
                            <div class="col-md-6">
                              <input
                                type="text"
                                data-parsley-group="step-1"
                                data-parsley-required="true"
                                class="form-control"
                                v-model="ADDOPERATOR.physical_address2"
                              />
                            </div>
                          </div>
                          <!-- end form-group -->

                         
                        </div>
                        <!-- end col-8 -->

                        <div class="col-lg-6">

                               <!-- end form-group -->
                          <div class="form-group row m-b-10">
                            <label class="col-md-3 col-form-label text-md-right">Bank Name</label>
                            <span class="text-danger">*</span>
                            <div class="col-md-6">
                              <select
                                class="form-control"
                                data-size="10"
                                data-live-search="true"
                                data-parsley-group="step-1"
                                data-parsley-required="true"
                                v-model="ADDOPERATOR.bankname_id"
                              >
                                <option value="0" selected>Select Bank Name</option>
                                <option
                                  v-bind:key="item.id"
                                  v-for="item in GETBANKS"
                                  :value="item"
                                >{{item.name}}</option>
                              </select>
                            </div>
                          </div>
                          <!-- end form-group -->
                          <!-- end form-group -->
                          <div class="form-group row m-b-10">
                            <label class="col-md-3 col-form-label text-md-right">Account Type</label>
                            <span class="text-danger">*</span>
                            <div class="col-md-6">
                              <select
                                class="form-control"
                                data-size="10"
                                data-live-search="true"
                                data-parsley-group="step-1"
                                data-parsley-required="true"
                                v-model="ADDOPERATOR.accounttype_id"
                              >
                                <option value="0" selected>Select Account Type</option>
                                <option
                                  v-bind:key="item.id"
                                  v-for="item in GETACCOUNTTYPE"
                                  :value="item"
                                >{{item.name}}</option>
                              </select>
                            </div>
                          </div>
                          <!-- end form-group -->
                         
                          <!-- begin form-group -->
                          <div class="form-group row m-b-10">
                            <label class="col-md-3 col-form-label text-md-right">Account Number</label>
                            <span class="text-danger">*</span>
                            <div class="col-md-6">
                              <input
                                type="text"
                                class="form-control"
                                data-live-search="true"
                                data-parsley-group="step-1"
                                data-parsley-required="true"
                                v-model="ADDOPERATOR.account_number"
                              />
                            </div>
                          </div>
                          <!-- end form-group -->

                          <br />
                          <br />
                         
                          <!-- begin form-group -->
                          <div class="form-group row m-b-10">
                            <label class="col-md-3 col-form-label text-md-right">Loan Amount</label>
                            <span class="text-danger">*</span>
                            <div class="col-md-6">
                              <CurrencyInput
                              id="cash"
                              v-model="TOTALINVOICEAMOUNT"
                              data-parsley-group="step-1"
                              data-parsley-required="true"
                              :currency="currency"
                              :auto-decimal-mode="false"
                              class="height-40 text-left demo__currency-input"
                            />
                            </div>
                          </div>
                          <!-- end form-group -->
                            <!-- end form-group -->
                          <div class="form-group row m-b-10">
                            <label class="col-md-3 col-form-label text-md-right">Loan Term</label>
                            <span class="text-danger">*</span>
                            <div class="col-md-6">
                              <select
                                class="form-control"
                                data-size="10"
                                data-live-search="true"
                                data-parsley-group="step-1"
                                data-parsley-required="true"
                                v-model="ADDOPERATOR.accounttype_id"
                              >
                                <option value="0" selected>Select Account Type</option>
                                <option
                                  v-bind:key="item.id"
                                  v-for="item in GETACCOUNTTYPE"
                                  :value="item"
                                >{{item.name}}</option>
                              </select>
                            </div>
                          </div>
                          <!-- end form-group -->
                            <!-- end form-group -->
                          <div class="form-group row m-b-10">
                            <label class="col-md-3 col-form-label text-md-right">Collection Type</label>
                            <span class="text-danger">*</span>
                            <div class="col-md-6">
                              <select
                                class="form-control"
                                data-size="10"
                                data-live-search="true"
                                data-parsley-group="step-1"
                                data-parsley-required="true"
                                v-model="ADDOPERATOR.accounttype_id"
                              >
                                <option value="0" selected>Select Collection Type</option>
                                <option
                                  v-bind:key="item.id"
                                  v-for="item in GETACCOUNTTYPE"
                                  :value="item"
                                >{{item.name}}</option>
                              </select>
                            </div>
                          </div>
                          <!-- end form-group -->
                            <!-- begin form-group -->
                            <div class="form-group row m-b-10">
                            <label class="col-md-3 col-form-label text-md-right">Debit Order Date</label>
                            <span class="text-danger">*</span>
                            <div class="col-md-6">
                              <div
                                class="input-group date"
                                id="debtidate"
                                data-date-format="dd M yyyy"
                              >
                                <input
                                  type="text"
                                  class="form-control"
                                  placeholder="Select Date"
                                  data-parsley-group="step-1"
                                  data-parsley-required="false"
                                />
                                <span class="input-group-addon">
                                  <i class="fa fa-calendar"></i>
                                </span>
                              </div>
                            </div>
                          </div>
                            <!-- end form-group -->

                                        
                        </div>

                      </div>
                    </div>
                    <!-- end col-8 -->
                  </div>
                  <!-- end row -->
                  <!-- end fieldset -->
                </div>
                <!-- end step-1 -->
                
                <!-- begin step-3 -->
                <div id="step-2">
                  <!-- begin fieldset -->
                  <fieldset>
                    <!-- begin row -->

                    <p class="text-center h4 mb-4">Upload Supporting Documents(e.g. Proof Of ID, Address And Bank Account)</p>
                    <div class="panel panel-primary" data-sortable-id="form-dropzone-1">
                      <div class="panel-heading">
                        <div class="panel-heading-btn"></div>
                        <h4 class="panel-title">Drop Files In Here</h4>
                      </div>
                      <div class="panel-body text-inverse">
                        <DropZoneControl
                          :acceptedFiles="acceptedFiles"
                          :ClearData="true"
                          v-on:fileadded="fileadded"
                          v-on:removedfile="removedfile"
                          :SessionID="ADDOPERATOR.documentsessionid"
                        ></DropZoneControl>
                      </div>
                    </div>
                    <br />
                    <div class="panel panel-primary" data-sortable-id="table-basic-7">
                      <!-- begin panel-heading -->
                      <div class="panel-heading ui-sortable-handle">
                        <h4 class="panel-title">Uploaded Files</h4>
                      </div>
                      <!-- end panel-heading -->
                      <!-- begin panel-body -->
                      <div class="panel-body">
                        <!-- begin table-responsive -->
                        <div class="table-responsive">
                          <table class="table table-striped m-b-0">
                            <thead>
                              <tr>
                                <th>Filename</th>
                                <th>Mimetype</th>
                                <th width="40%">Document Type</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr :key="'docs-'+ index" v-for="(item,index) in GETUPLOADEDFILES">
                                <td>{{item.filename}}</td>
                                <td>{{item.mimetype}}</td>
                                <td>
                                  <select
                                    class="form-control col-sm-4"
                                    data-size="10"
                                    data-live-search="true"
                                    data-parsley-group="step-3"
                                    data-parsley-min="1"
                                    data-parsley-required="true"
                                    v-model="item.customType"
                                  >
                                    <option value="0" selected>Select Document Type</option>
                                    <option
                                      v-bind:key="item._id"
                                      v-for="item in GETALLDOCUMENTTYPE"
                                      :value="item._id"
                                    >{{item._name}}</option>
                                  </select>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                        <!-- end table-responsive -->
                      </div>
                      <!-- end panel-body -->
                      <!-- begin hljs-wrapper -->
                    </div>
                    <!--End dropzone!-->

                    <br />
                    <!--Start Table!-->

                    <!-- end row -->
                  </fieldset>
                </div>
                <!-- end step-3 -->

                <!-- begin step-3 -->
                <div id="step-3">
                  <div class="jumbotron m-b-0 text-center">
                    <h2 class="text-inverse">Application Submitted Successfully</h2>
                    <p
                      class="m-b-30 f-s-16"
                    >Booking Ref {{BOOKINGNUMBER}} Has Been Sent To The Client</p>
                    
                    <button
                            type="button"
                            class="btn btn-xl btn-primary"
                            @click="captureNupayContract()"
                            v-show="!ISCONTRACTCAPTURED"
                            :disabled="$wait.is('captureNupayContract')"
                          >
                            <v-wait v-bind:for="'captureNupayContract'">
                              <template slot="waiting">
                                <i class="fas fa-spinner fa-spin"></i>
                              </template>
                              CAPTURE NUPAY CONTRACT
                            </v-wait>
                          </button>
                    
                    
                    <button
                        class="btn btn-success btn-sm btnClipboardJS"
                        type="button"
                        v-show="ISCONTRACTCAPTURED"
                        :data-clipboard-text="`${ADDOPERATOR.displayname} - Booking No: ${BOOKINGNUMBER}`"
                      >Copy For Booking Calendar</button>
                    <p>
                    </p>
                  </div>
                </div>
                <!-- end step-3 -->
              </div>
              <!-- end wizard-content -->
            </div>
            <!-- end wizard -->
          </form>
          <!-- end wizard-form -->
        </div>

        <!-- end col-12 -->
      </div>

      <!-- end row -->
</div>

<!-- begin row -->

 <!-- begin tab-pane -->
 <div class="tab-pane fade" id="nav-pills-tab-2">
  <div class="row">
  <!-- begin col-12 -->
  <div class="col-lg-12">
    <!-- begin wizard-form -->
    <div class="table-responsive">
      <table class="table table-invoice">
        <thead>
          <tr>
            <th>FULL NAME</th>
            <th>MOBILE NO</th>
            <th>LICENSE PLATE</th>
            <th>MODEL NAME</th>
            <th>BOOKING REF</th>
            <th>CURRENT CONTRACT AMOUNNT</th>
            <th>DEBIT AMOUNT</th>
            <th>NEED ATTENTION</th>
            <th>INSTALLED</th>
            <th class="text-nowrap hidden-print">Action</th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(item) in GETALLBOOKINGS"
            v-bind:key="item.id"
          >
            <td>{{item.firstname}}  {{item.surname}}</td>
            <td>{{item.mobilenumber}}</td>
            <td>{{item.licence_number}}</td>
            <td>{{item.make}} {{item.model}}</td>
            <td>{{item.bookingnumber}}</td>
            <td>{{item.nupaycontractamount | newcurrencywithSymbol}}</td>
            <td>{{item.debitamount | newcurrencywithSymbol}}</td>
            <td>
                <span
                  class="badge f-s-12"
                  v-bind:class="{ 'badge-danger': item.debitamount !== item.nupaycontractamount, 'label-secondary':item.debitamount === item.nupaycontractamount  }"
                >{{item.debitamount !== item.nupaycontractamount}}</span>
            </td>
            <td>
                <span
                  class="badge f-s-12"
                  v-bind:class="{ 'badge-success': item.isinstalled, 'label-secondary':!item.isinstalled  }"
                >{{item.isinstalled}}</span>
            </td>
            <td class="with-btn valign-middle" nowrap>
                      <button
                        type="button"
                        class="btn btn-sm btn-success m-r-2"
                        v-if="item.debitamount !== item.nupaycontractamount"
                        @click="AddContract(item)"
                        :disabled="$wait.is('recaptureNupayContract-' + item.id)"
                      >
                        <v-wait v-bind:for="'recaptureNupayContract-' + item.id">
                          <template slot="waiting">
                            <i class="fas fa-spinner fa-spin"></i>
                          </template>
                          Capture Contract
                        </v-wait>
                      </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
  </div>

</div>
  <!-- end tab-pane -->


       <!-- Add Modal HTML START-->
       <div id="captureNuPayContractors" class="modal fade">
                <div class="modal-dialog">
                  <div class="modal-content">
                    <form>
                      <div class="modal-header">
                        <h4 class="modal-title">Capture Debit Order</h4>
                        <button
                          type="button"
                          class="close"
                          data-dismiss="modal"
                          aria-hidden="true"
                        >&times;</button>
                      </div>
                      <div class="modal-body">
                        <div class="col-md-12">
                          <!-- begin form-group row -->
                          <div class="form-group row m-b-10">
                            <label class="col-md-3 text-md-right col-form-label">Bank Name</label>
                            <div class="col-md-9">
                              <select
                                class="form-control"
                                data-size="10"
                                data-live-search="true"
                                data-parsley-group="step-1"
                                data-parsley-required="true"
                                v-model="ADDOPERATOR.bankname_id"
                              >
                                <option value="0" selected>Select Bank Name</option>
                                <option
                                  v-bind:key="item.id"
                                  v-for="item in GETBANKS"
                                  :value="item"
                                >{{item.name}}</option>
                              </select>
                            </div>
                          </div>
                          <!-- end form-group row -->

                          <!-- begin form-group row -->
                          <div class="form-group row m-b-10">
                            <label class="col-md-3 text-md-right col-form-label">Account Type</label>
                            <div class="col-md-9">
                              <select
                                class="form-control"
                                data-size="10"
                                data-live-search="true"
                                data-parsley-group="step-1"
                                data-parsley-required="true"
                                v-model="ADDOPERATOR.accounttype_id"
                              >
                                <option value="0" selected>Select Account Type</option>
                                <option
                                  v-bind:key="item.id"
                                  v-for="item in GETACCOUNTTYPE"
                                  :value="item"
                                >{{item.name}}</option>
                              </select>
                            </div>
                          </div>
                          <!-- end form-group row -->
                           <!-- begin form-group row -->
                          <div class="form-group row m-b-10">
                            <label class="col-md-3 text-md-right col-form-label">Account Number</label>
                            <div class="col-md-9">
                              <input
                                type="text"
                                placeholder="Enter Account Number"
                                class="form-control"
                                minlength="3"
                                data-parsley-group="step-1"
                                data-parsley-required="true"
                                v-model="CREATECONTRACT.DebtorAccountNumber"
                              />
                            </div>
                          </div>
                          <!-- end form-group row -->
                           <!-- begin form-group row -->
                          <div class="form-group row m-b-10">
                            <label class="col-md-3 text-md-right col-form-label">Debit Date</label>
                            <div class="col-md-9">
                              <div
                                class="input-group date debtidate"
                                id="debtidate"
                                data-date-format="dd M yyyy"
                              >
                                <input
                                  type="text"
                                  class="form-control"
                                  placeholder="Select Date"
                                  data-parsley-group="step-1"
                                  data-parsley-required="false"
                                />
                                <span class="input-group-addon">
                                  <i class="fa fa-calendar"></i>
                                </span>
                              </div>
                            </div>
                          </div>
                          <!-- end form-group row -->
                        </div>
                      </div>
                      <div class="modal-footer">
                        <input
                          type="button"
                          class="btn btn-default"
                          data-dismiss="modal"
                          value="Cancel"
                        />
                        <button
                          type="button"
                          class="btn btn-sm btn-success width-80"
                          @click="recaptureNupayContract()"
                          :disabled="$wait.is('recaptureNupayContract')"
                        >
                          <v-wait v-bind:for="'recaptureNupayContract'">
                            <template slot="waiting">
                              <i class="fas fa-spinner fa-spin"></i>
                            </template>
                            Add
                          </v-wait>
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
        </div>
      <!-- Add Modal HTML START-->

        <!-- Add Modal HTML START-->
        <div id="captureNuPayContractors" class="modal fade">
                <div class="modal-dialog">
                  <div class="modal-content">
                    <form>
                      <div class="modal-header">
                        <h4 class="modal-title">Capture Debit Order</h4>
                        <button
                          type="button"
                          class="close"
                          data-dismiss="modal"
                          aria-hidden="true"
                        >&times;</button>
                      </div>
                      <div class="modal-body">
                        <div class="col-md-12">
                          <!-- begin form-group row -->
                          <div class="form-group row m-b-10">
                            <label class="col-md-3 text-md-right col-form-label">Bank Name</label>
                            <div class="col-md-9">
                              <select
                                class="form-control"
                                data-size="10"
                                data-live-search="true"
                                data-parsley-group="step-1"
                                data-parsley-required="true"
                                v-model="ADDOPERATOR.bankname_id"
                              >
                                <option value="0" selected>Select Bank Name</option>
                                <option
                                  v-bind:key="item.id"
                                  v-for="item in GETBANKS"
                                  :value="item"
                                >{{item.name}}</option>
                              </select>
                            </div>
                          </div>
                          <!-- end form-group row -->

                          <!-- begin form-group row -->
                          <div class="form-group row m-b-10">
                            <label class="col-md-3 text-md-right col-form-label">Account Type</label>
                            <div class="col-md-9">
                              <select
                                class="form-control"
                                data-size="10"
                                data-live-search="true"
                                data-parsley-group="step-1"
                                data-parsley-required="true"
                                v-model="ADDOPERATOR.accounttype_id"
                              >
                                <option value="0" selected>Select Account Type</option>
                                <option
                                  v-bind:key="item.id"
                                  v-for="item in GETACCOUNTTYPE"
                                  :value="item"
                                >{{item.name}}</option>
                              </select>
                            </div>
                          </div>
                          <!-- end form-group row -->
                           <!-- begin form-group row -->
                          <div class="form-group row m-b-10">
                            <label class="col-md-3 text-md-right col-form-label">Account Number</label>
                            <div class="col-md-9">
                              <input
                                type="text"
                                placeholder="Enter Account Number"
                                class="form-control"
                                minlength="3"
                                v-model="CREATECONTRACT.DebtorAccountNumber"
                              />
                            </div>
                          </div>
                          <!-- end form-group row -->
                           <!-- begin form-group row -->
                          <div class="form-group row m-b-10">
                            <label class="col-md-3 text-md-right col-form-label">Debit Date</label>
                            <div class="col-md-9">
                              <div
                                class="input-group date debtidate"
                                id="debtidate"
                                data-date-format="dd M yyyy"
                              >
                                <input
                                  type="text"
                                  class="form-control"
                                  placeholder="Select Date"
                                  data-parsley-group="step-1"
                                  data-parsley-required="false"
                                />
                                <span class="input-group-addon">
                                  <i class="fa fa-calendar"></i>
                                </span>
                              </div>
                            </div>
                          </div>
                          <!-- end form-group row -->
                        </div>
                      </div>
                      <div class="modal-footer">
                        <input
                          type="button"
                          class="btn btn-default"
                          data-dismiss="modal"
                          value="Cancel"
                        />
                        <button
                          type="button"
                          class="btn btn-sm btn-success width-80"
                          @click="recaptureNupayContract()"
                          :disabled="$wait.is('recaptureNupayContract')"
                        >
                          <v-wait v-bind:for="'recaptureNupayContract'">
                            <template slot="waiting">
                              <i class="fas fa-spinner fa-spin"></i>
                            </template>
                            Add
                          </v-wait>
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
        </div>
      <!-- Add Modal HTML START-->

      <!-- end row -->

      <!-- begin #Main Body Template -->

      <!-- end #content -->
    </div>


    <!-- begin scroll to top btn -->
    <pagescroller />
    <!-- end scroll to top btn -->
  </div>
</template>


	<!-- The template to display files available for upload -->

<script>
// @ is an alias to /src
//import LoginComp from '@/components/LoginComp.vue'

import CapturePhotosControl from "@/components/common/CapturePhotosControl.vue";
import DropZoneControl from "@/components/common/DropZoneControl.vue";
import headerComp from "@/components/common/header.vue";
import headerpagebreadcrumb from "@/components/common/pageheader-breadcrumb.vue";
import pageloader from "@/components/common/pageloader.vue";
import pagescroller from "@/components/common/pagescroller.vue";
import sidebarComp from "@/components/common/sidebar.vue";
import draggable from "vuedraggable";
const saIdParser = require("south-african-id-parser");

import { createNamespacedHelpers } from "vuex";
const { mapGetters, mapActions } = createNamespacedHelpers("TO");

export default {
  name: "ADDOPERATOR",
  data: function () {
    return {
      ADDAGENT:{},
      GETOPERATORS: [],
      acceptedFiles: "application/pdf",
      ROLES: this.$store.getters["auth/GETUSERROLES"],
      GETALLTITLES: [],
      GETALLGENDERS: [],
      GETALLSUBURBS: [],
      status: true,
      skip: 0,
      len: 1000,
      GETALLCOUNTRY: this.$store.getters["lookups/GETALLCOUNTRY"],
      GETALLCITY: this.$store.getters["lookups/GETALLCITY"],
      GETUPLOADEDFILES: [],
      GETALLDOCUMENTTYPE: this.$store.getters["lookups/GETALLDOCUMENTTYPE"],
      USERPROFILE: this.$store.getters["auth/USERPROFILE"],
      taxiassociationid: this.$store.getters["auth/ASSIGNEDTAXIASSOCIATION"].id,
      isSystemAdmin: this.$store.getters["auth/USERPROFILE"].is_system_admin,
      context: this,
      id: this.$route.params.id,
      selectedTitleId: undefined,
      selectedGenderId: undefined,
      selectedUserGroupId: undefined,
      CITIES: [],
      IsForeign: false,
      ADDSUBURB: {
        _city_id: null,
        _cityname: "",
        _description: "",
        _code: "",
        _postalcode: "",
        _added_by: this.$store.getters["auth/USERPROFILE"].system_usersid,
      },
      isIdNumberExists: true,
      isIdNumberValid: undefined,
      IDNUMBER: "",
      SELECTEDOPERATORS: {},
      ADDOPERATOR: {
        taxiassociationid: this.$store.getters["auth/ASSIGNEDTAXIASSOCIATION"].id,
        taxi_operator_id: 0,
        taxi_operator_vehicle_id:0,
        firstname: "",
        surname: "",
        mobilenumber: "",
        id_number: "",
        passport_number: "",
        date_of_birth: "",
        bankname_id: null,
        accounttype_id: null,
        account_number: "",
        debit_date: "",
        physical_address: "",
        physical_address2: "",
        physical_suburb_id: null,
        postal_address: "",
        postal_address2: "",
        postal_suburb_id: null,
        addedby: this.$store.getters["auth/USERPROFILE"].system_usersid,
        licence_number: "",
        vehiclemodel_id: 0,
        displayname: "",
        vin_number: "",
        engine_number: "",
        tara: "",
        vehicle_register_number: "",
        licence_expiry_date: "",
        vehicle_engine_id: 0,
        color: "",
        physicaladdress_id: null,
        postaladdress_id: null,
        documentsessionid: this.$uuid.v4(),
        dropzonefiles: [
          {
            customType: 0,
            fileId: "",
          },
        ],
      },
      hacksmartwizard: false,
      GETVEHICLES: [],
      GETALLMODEL: [],
      GETALLENGINETYPES: this.$store.getters["lookups/GETALLENGINETYPES"],
      CLIENTID:0,
      BOOKINGNUMBER:"",
      PREVIOUSNUCONTRACT:"",
      GETALLBOOKINGS:[],
      REVIOUSCONTRACT:"",
      BANKID:"",
      ACCOUNTYPE:"",
      ISCONTRACTCAPTURED:false,
      dataLoaded:false,
      CANCELCONTRACT: {
        SystemUserId: this.$store.getters["auth/USERPROFILE"].system_usersid,
        DebtorPhoneNumber: "",
        contractReference: "",
        clientReference: "",
        mandateID: "",
      },
      CREATECONTRACT: {
        TaxiAssociationId : this.$store.getters["auth/ASSIGNEDTAXIASSOCIATION"].id,
        TaxiOperatorId :1,
        SystemUserId : this.$store.getters["auth/USERPROFILE"].system_usersid,
        Frequency: "MNTH",
        CollectionDay: "",
        ClientReference: "",
        DebtorName: "",
        DebtorId: "",
        DebtorIdType: "2", //* ID NUMBER */
        DebtorAccountNumber: "",
        DebtorAccountType: "",
        DebtorBankId: "",
        DebtorBranchNumber: "",
        DebtorPhoneNumber: "",
        CollectionAmount: 5,
        StartDate: "",
        BDSeerviceidTypeCode: "FMT"
      }
    };
  },
  beforeCreate: function () {
    document.body.classList.remove("bg-white");
  },
  beforeMount: () => {
    // this.loadCities(this.context);
  },
  components: {
    headerpagebreadcrumb,
    headerComp,
    sidebarComp,
    pagescroller,
    pageloader,
    CapturePhotosControl,
    DropZoneControl,
    draggable,
  },
  computed: {
    ...mapGetters(["GETALLPROVINCES"]),
    LoggedInUserFullName: function () {
      return this.USERPROFILE.first_name + " " + this.USERPROFILE.surname;
    },
  },
  watch: {
    IDNUMBER: function (val) {
        if (val.toString().length > 11) {
          if (saIdParser.validate(val)) {
            this.isIdNumberValid = true;
            this.ADDOPERATOR.id_number = val;
          } else {
            this.isIdNumberValid = false;
            //this.isIdNumberExists = true;
            this.context.$Notify.Error("Not A Valid SA ID.");
          }
        }
    },
  },
  methods: {
    ...mapActions(["loadcitybyprovince", "add"]),
    fileadded: (data) => {
      console.log("Header:fileadded");
      console.log(data);
      // this.$emit('init')
      const context = $("#wizard").data("context");
      context.GETUPLOADEDFILES.push({
        filename: data.name,
        mimetype: data.type,
        customType: data.customType,
        fileId: data.fileId,
      });
    },
    removedfile: function (data) {
      console.log("Header:fileadded");
      console.log(data);
      //this.$emit('init')
      const context = $("#wizard").data("context");
      context.GETUPLOADEDFILES = context.GETUPLOADEDFILES.filter(item => item.fileId !== data.fileId);
    },
    deletefile: (context, fileId, userID) => {
      context.$Notify
        .YesNo(false, { context, fileId, userID })
        .then((value) => {
          if (value) {
            value.context.$wait.start("add");
            value.router = value.context.$router;
            value.context.$store
              .dispatch("fileupload/delete", {
                id: value.fileId,
                deletedby: value.userID,
              })
              .then(() => {
                $("#AddModal").modal("hide");
                value.context.$wait.end("add");
                value.context.$Notify.Success("Successfully Deleted!", "");
              })
              .catch((error) => {
                value.context.$wait.end("add");

                value.context.$Notify.Error(error.data[0].message, "");
              })
              .finally(() => {});
          }
        });
    },
    AddContract(item) {
      const context = $("#wizard").data("context");
      context.CREATECONTRACT.TaxiOperatorId = item.taxi_operator_id;
      context.CREATECONTRACT.SystemUserId = item.systemuserid;
      context.CREATECONTRACT.DebtorName = item.firstname + " " + item.surname;
      context.CREATECONTRACT.DebtorId = item.id_number;
      context.CREATECONTRACT.ClientReference = item.clientreference
      
      context.CREATECONTRACT.DebtorPhoneNumber = item.mobilenumber
      context.CREATECONTRACT.CollectionAmount = item.debitamount

      context.CANCELCONTRACT.DebtorPhoneNumber = item.mobilenumber
      context.CANCELCONTRACT.contractReference = item.nupaycontract_ref
      context.CANCELCONTRACT.clientReference = item.clientreference
      context.CANCELCONTRACT.mandateID = item.mandateid
      context.CLIENTID = item.systemuserid;

      $("#captureNuPayContractors").modal("show");
    },
    loadLookUps(context) {
      
      context.$store
        .dispatch("lookups/init", {
          router: context.$router,
        })
        .then((data) => {
          console.log("loadLookUps found: ", data);
          
          context.GETALLTITLES = data.LOADEDTITLES;
          context.GETALLGENDERS = data.LOADEDGENDER;
          context.GETALLDOCUMENTTYPE = data.LOADEDDOCUMENTTYPE;
        })
        .catch((error) => {
          console.log("suburbs error:", error);
        })
        .finally(() => {});
    },
    recaptureNupayContract() {
      const context = $("#wizard").data("context");

      var CollectionDay = "";

      
      context.CREATECONTRACT.DebtorBranchNumber = context.ADDOPERATOR.bankname_id.universalbranchcode;
      context.CREATECONTRACT.DebtorBankId = context.ADDOPERATOR.bankname_id.bankid;
      context.CREATECONTRACT.DebtorAccountType = context.ADDOPERATOR.accounttype_id.code;

      if($("#debtidate").datepicker("getDate").getDate().length > 1)
      {
        CollectionDay = $("#debtidate").datepicker("getDate").getDate()
      }
      else
      {
        CollectionDay = "0" + $("#debtidate").datepicker("getDate").getDate()
      }

      var debtidate = context.$Toolkit.ConvertToDebitDateDate(
            $("#debtidate").datepicker("getDate")
          );

      
      context.CREATECONTRACT.CollectionDay =  CollectionDay;
      context.CREATECONTRACT.StartDate = debtidate;
      
      
      context.$wait.start("recaptureNupayContract");
      context.$store
        .dispatch("fleetmanagement/CaptureNuPayContract", context.CREATECONTRACT)
        .then((results) => {
          debugger;
          if(!results.Status){
            context.$Notify.Error(results.Data.Status,
            results.Data.ErrorMessage.ErrorMessage);
            return;
          } else {

            context.$store
            .dispatch("fleetmanagement/UpdateContract", {
              clientsid: context.CLIENTID,
              contract_amount: context.CREATECONTRACT.CollectionAmount,
              nupaycontract_amount: context.CREATECONTRACT.CollectionAmount,
              nupaycontractref: results.Data.referenceNumbers.contractReference,
              mandate_id: results.Data.referenceNumbers.mandate_id,
              addedby: context.$store.getters["auth/USERPROFILE"].system_usersid,
              contractpayload: JSON.stringify(results.Data)
            }).then((resultss) => {
              if(resultss.status){
                context.context.$Notify.Success("CONTRACT UPDATED","");
                $("#captureNuPayContractors").modal("hide");
              } else {
                context.context.$Notify.Error("FAILED TO UPDATE CONTRACT","");
                return;
              }
            });

            if(context.CANCELCONTRACT.mandateID !== undefined && context.CANCELCONTRACT.mandateID !== null && context.CANCELCONTRACT.mandateID !== ""){
              context.$store
            .dispatch("fleetmanagement/CancelNuPayContract", context.CANCELCONTRACT)
            .then((resultsss) => {
              if(resultsss.Status){
                context.$Notify.Success("PREVIOUS CONTRACT CANCELLED","");
              } else {
                context.$Notify.Error("FAILED TO CANCEL CONTRACT","");
                return;
              }
            })
            }

            context.ISCONTRACTCAPTURED = true;
          }
        }).catch((error) => {
          context.context.$Notify.Error(error.Data.Status,
          error.Data.ErrorMessage.ErrorMessage);
        }).finally(()=>{
          context.$wait.end("recaptureNupayContract");
        });
    },
    captureNupayContract() {
      const context = $("#wizard").data("context");
      context.$wait.start("captureNupayContract");
      context.$store
        .dispatch("fleetmanagement/CaptureNuPayContract", context.CREATECONTRACT)
        .then((results) => {
          debugger;
          if(!results.Status){
            context.$Notify.Error(results.Data.Status,
            results.Data.ErrorMessage.ErrorMessage);
            return;
          } else {

            context.$store
            .dispatch("fleetmanagement/UpdateContract", {
              clientsid: context.CLIENTID,
              contract_amount: context.CREATECONTRACT.CollectionAmount,
              nupaycontract_amount: context.CREATECONTRACT.CollectionAmount,
              nupaycontractref: results.Data.referenceNumbers.contractReference,
              mandate_id: results.Data.referenceNumbers.mandate_id,
              addedby: context.$store.getters["auth/USERPROFILE"].system_usersid,
              contractpayload: JSON.stringify(results.Data)
            }).then((resultss) => {
              if(resultss.status){
                context.context.$Notify.Success("CONTRACT UPDATED","");
              } else {
                context.context.$Notify.Error("FAILED TO UPDATE CONTRACT","");
                return;
              }
            });

            if(context.CANCELCONTRACT.mandateID !== undefined && context.CANCELCONTRACT.mandateID !== null && context.CANCELCONTRACT.mandateID !== ""){
              context.$store
            .dispatch("fleetmanagement/CancelNuPayContract", context.CANCELCONTRACT)
            .then((resultsss) => {
              if(resultsss.Status){
                context.$Notify.Success("PREVIOUS CONTRACT CANCELLED","");
              } else {
                context.$Notify.Error("FAILED TO CANCEL CONTRACT","");
                return;
              }
            })
            }

            context.ISCONTRACTCAPTURED = true;
          }
        }).catch((error) => {
          context.context.$Notify.Error(error.Data.Status,
          error.Data.ErrorMessage.ErrorMessage);
        }).finally(()=>{
          context.$wait.end("captureNupayContract");
        });
    },
    getBookingByTaxiAssId: (context,id) => {
      context.$store
        .dispatch("fleetmanagement/getAllBookingByTaxiAssId", {
          router: context.$router,
          id,
        })
        .then((results) => {
          console.log("GETALLBOOKINGS: ", results);

          context.GETALLBOOKINGS = results.data[0].data;
        })
        .catch((error) => {
          console.log("vehicle models errors: ", error);
        }).finally((error)=>{
          var options = {
                    responsive: false,
                    autoFill: false,
                    colReorder: false,
                    keys: false,
                    rowReorder: false,
                    select: false,
                    order: [[0, 'asc']]
                  };

                  // if ($(window).width() <= 767) {
                  //   options.rowReorder = false;
                  //   options.colReorder = false;
                  //   options.autoFill = false;
                  // }

                  if(!context.dataLoaded){
                    $("#data-table-default").DataTable(options);
                    context.dataLoaded = true;
                  }

 	              $(".table-invoice").DataTable(options);
        });
    },
    getAllVehiclemodels: (context) => {
      context.$store
        .dispatch("OperatorVehicle/getVehicleModels", {
          router: context.$router,
        })
        .then((data) => {
          console.log("vehicle models: ", data);

          context.GETALLMODEL = data;
        })
        .catch((error) => {
          console.log("vehicle models errors: ", error);
        });
    },
    getVehiclesbyOperatorId: (context,id) => {
      context.$store
        .dispatch("OperatorVehicle/getbyOperatorId",
        {
          _dataObj: {
            router: context.$router,
          id,
          }
        })
        .then((data) => {
          console.log("operators found: ", data);
          context.GETVEHICLES = data;
        })
        .catch((error) => {
          console.log("operator errors: ", error);
          context.$Notify.Error(error.data[0].message, "");
        });
    },
    getAllOperatorByTaxiAssId: (context) => {
      context.$store
        .dispatch("TO/getAllByTaxiAssIdNoPic", {
          router: context.$router,
          id: context.taxiassociationid,
        })
        .then((data) => {
          console.log("operators found: ", data);
          context.GETOPERATORS = data;
        })
        .catch((error) => {
          console.log("operator errors: ", error);
          context.$Notify.Error(error.data[0].message, "");
        });
    },
    addTaxiOperator: (context, item) => {

      context.$Notify.YesNo(false, { context, item }).then((value) => {
        if (value) {
          $(".sw-btn-next")
            .text("")
            .append('<i class="fas fa-spinner fa-spin"></i>')
            .attr("disabled", "disabled");
          value.router = value.context.$router;
          value.context.$store
            .dispatch("TO/add", {
              _dataObj: value,
            })
            .then(() => {
              console.log("added results vue page: ");

              // value.context.hacksmartwizard = true;
              // $("#wizard").smartWizard("next");
              // $(".sw-btn-next")
              //   .text("Done")
              //   .attr("disabled", "disabled");

              $(".sw-btn-next").text("Done").attr("disabled", "disabled");
              $(".sw-btn-prev").attr("disabled", "disabled");
              $("#wizard").data("taxioperatoradded", true);
              $("#wizard").smartWizard("next");
              value.context.$Notify.Success("Successfully Added!", "");
            })
            .catch((error) => {
              value.context.$wait.end("add");
              $(".sw-btn-next").removeAttr("disabled");
              console.log("these are the add operator errors: ", error);
              value.context.$Notify.Error(error.data.message);
              if (Array.isArray(error.data))
                value.context.$Notify.Error(error.data[0].message, "");
              else value.context.$Notify.Error(error.data.message, "");
            })
            .finally(() => {});
        }
      });
    },

    addNewSuburb: (context, item) => {
      context.$Notify.YesNo(false, { context, item }).then((value) => {
        if (value) {
          value.context.$wait.start("add+");
          value.router = value.context.$router;
          value.context.$store
            .dispatch("suburb/add", {
              _dataObj: {
                item: value.item,
                router: value.router,
              },
            })
            .then((data) => {
              $("#AddModal").modal("hide");
              value.context.$wait.end("add+");
              console.log("suburb added: ", data);
              value.context.$Notify.Success("Successfully Added!", "");
              context.loadSuburbs(context);
            })
            .catch((error) => {
              value.context.$wait.end("add+");

              value.context.$Notify.Error(error.data[0].message, "");
            })
            .finally(() => {});
        }
      });
    },
    loadOperatorImage(context) {
      if (context.id != ":id")
      {
        
        context.OPERATORIMAGE = context.DATACOLLECTION.filter(el => el.datacollection_id == context.id)[0].operatorcarddisk_image[0].data;
      }
    },

    loadCities(context) {
      context.$store
        .dispatch("city/getall", {
          _dataObj: {
            router: context.$router,
          },
        })
        .then((data) => {
          console.log("cities found:", data);
          context.CITIES = data;
        })
        .catch((error) => {
          console.log("cities errors:", error);
        })
        .finally(() => {});
    },

    loadBanks(context) {
      context.$store
        .dispatch("fleetmanagement/GetBanks", {
          router: context.$router,
        })
        .then((results) => {
          //console.log("banks found: ", data);
          context.GETBANKS = results.data[0].data; 
        })
        .catch((error) => {
          console.log("load banks error:", error);
        })
        .finally(() => {});
    },

    loadAccountType(context) {
      context.$store
        .dispatch("fleetmanagement/GetAccountTypes", {
          router: context.$router,
        })
        .then((results) => {
          //console.log("account type found: ", data);
          context.GETACCOUNTTYPE = results.data[0].data; 
        })
        .catch((error) => {
          console.log("load account types error:", error);
          
        })
        .finally(() => {});
    },

    loadSuburbs(context) {
      context.$store
        .dispatch("TO/getSuburbs", {
          router: context.$router,
          status: context.status,
          skip: context.skip,
          len: context.len,
        })
        .then((data) => {
          console.log("suburbs found: ", data);

          context.GETALLSUBURBS = data;
        })
        .catch((error) => {
          console.log("suburbs error:", error);
        })
        .finally(() => {});
    },
  },
  mounted: function () {
    // $("#selectCity").select2({
    //   multiple: false,
    //   placeholder: "Select a City",
    //   allowClear: true
    // });

    this.loadLookUps(this.context);

    var clipboard = new ClipboardJS(".btnClipboardJS");

    clipboard.on("success", function (e) {
      $(e.trigger).tooltip({
        title: "Copied",
        placement: "top",
      });
      $(e.trigger).tooltip("show");
      setTimeout(function () {
        var bootstrapVersion = handleCheckBootstrapVersion();
        if (bootstrapVersion >= 3 && bootstrapVersion < 4) {
          $(e.trigger).tooltip("destroy");
        } else if (bootstrapVersion >= 4 && bootstrapVersion < 5) {
          $(e.trigger).tooltip("dispose");
        }

        const context = $("#wizard").data("context");
        context.$router.push({ path: '/FleetManagment/Bookings' })
        
      }, 500);
    });

    $("#licence_expiry_date").datepicker({
      todayHighlight: false,
      autoclose: true,
      startDate: date,
    })

    $("#selectEngine").select2({
      multiple: false,
      placeholder: "Select a Engine Type",
      allowClear: false,
    }).on("select2:select", function (e) {
      const context = $("#wizard").data("context");
      context.ADDOPERATOR.vehicle_engine_id = parseInt($("#selectEngine").val())
    });

    $("#selectModel").select2({
      multiple: false,
      placeholder: "Select a Make/Model",
      allowClear: false,
    }).on("select2:select", function (e) {
      const context = $("#wizard").data("context");
      context.ADDOPERATOR.vehiclemodel_id = parseInt($("#selectModel").val())
    });
    

    $("#selectPhysicalSuburb").select2({
      multiple: false,
      placeholder: "Select a suburb",
      allowClear: false,
    }).on("select2:select", function (e) {
      const context = $("#wizard").data("context");
      context.ADDOPERATOR.physicaladdress_id = parseInt($("#selectPhysicalSuburb").val())
    });

    $("#selectPostalSuburb").select2({
      multiple: false,
      placeholder: "Select a suburb",
      allowClear: false,
    }).on("select2:select", function (e) {
      const context = $("#wizard").data("context");
      context.ADDOPERATOR.postal_suburb_id = parseInt($("#selectPostalSuburb").val())
    });

    App.init();
    this.getAllOperatorByTaxiAssId(this.context);
    this.getAllVehiclemodels(this.context);
    this.getBookingByTaxiAssId(this.context,this.context.taxiassociationid);


    this.loadCities(this.context);
    this.loadBanks(this.context);
    this.loadAccountType(this.context);
    this.loadSuburbs(this.context);
    

    $("#selectoperatorvehicle").select2({
      multiple: false,
      placeholder: "Select A Vehicle",
      allowClear: false,
    }).on("select2:select", function (e) {

    debugger;

    const context = $("#wizard").data("context");

    var SELECTEDVEHICLES = context.GETVEHICLES.filter(item => item._taxi_operator_vehicles_id === parseInt($("#selectoperatorvehicle").val()))[0];

    context.ADDOPERATOR.licence_number = SELECTEDVEHICLES._licence_number;
    context.ADDOPERATOR.vehiclemodel_id = SELECTEDVEHICLES._vehiclemodel_id;
    context.ADDOPERATOR.displayname = SELECTEDVEHICLES._vehicle_model + " " + SELECTEDVEHICLES._vehicle_color;
    context.ADDOPERATOR.vin_number = SELECTEDVEHICLES._vin_number;
    context.ADDOPERATOR.engine_number = SELECTEDVEHICLES._engine_number;
    context.ADDOPERATOR.tara = SELECTEDVEHICLES._tara;
    context.ADDOPERATOR.vehicle_register_number = SELECTEDVEHICLES._vehicle_register_number;
    context.ADDOPERATOR.licence_expiry_date = SELECTEDVEHICLES._license_expiry_date;
    context.ADDOPERATOR.vehicle_engine_id = SELECTEDVEHICLES._vehicle_engine_id;
    context.ADDOPERATOR.color = SELECTEDVEHICLES._vehicle_color;
    context.ADDOPERATOR.taxi_operator_vehicle_id = SELECTEDVEHICLES._taxi_operator_vehicles_id;
  

    });



    $("#selectoperatorpicker").select2({
      multiple: false,
      placeholder: "Select A Taxi Operator",
      allowClear: false,
    }).on("select2:select", function (e) {

    debugger;

    const context = $("#wizard").data("context");

    context.SELECTEDOPERATORS = context.GETOPERATORS.filter(item => item.taxi_operator_id === parseInt($("#selectoperatorpicker").val()))[0];

    context.ADDOPERATOR.firstname = context.SELECTEDOPERATORS.firstname;
    context.ADDOPERATOR.surname = context.SELECTEDOPERATORS.surname;
    context.ADDOPERATOR.mobilenumber = context.SELECTEDOPERATORS.mobilenumber;
    context.ADDOPERATOR.passport_number = context.SELECTEDOPERATORS.passport_number;
    //context.ADDOPERATOR.bankname_id = context.SELECTEDOPERATORS.bankname_id;
    //context.ADDOPERATOR.accounttype_id = context.SELECTEDOPERATORS.accounttype_id;
    if(context.SELECTEDOPERATORS.physical_address !== undefined)
    context.ADDOPERATOR.physical_address = context.SELECTEDOPERATORS.physical_address;
    if(context.SELECTEDOPERATORS.physical_address2 !== undefined)
    context.ADDOPERATOR.physical_address2 = context.SELECTEDOPERATORS.physical_address2;
    if(context.SELECTEDOPERATORS.postal_address !== undefined)
    context.ADDOPERATOR.postal_address = context.SELECTEDOPERATORS.postal_address;
    if(context.SELECTEDOPERATORS.postal_address2 !== undefined)
    context.ADDOPERATOR.postal_address2 = context.SELECTEDOPERATORS.postal_address2;
    context.IDNUMBER = context.SELECTEDOPERATORS.id_number;
    context.ADDOPERATOR.taxi_operator_id = context.SELECTEDOPERATORS.taxi_operator_id;
    context.ADDOPERATOR.physicaladdress_id = context.SELECTEDOPERATORS.physicaladdress_id;
    context.ADDOPERATOR.postaladdress_id = context.SELECTEDOPERATORS.postaladdress_id;

    context.ADDOPERATOR.physical_address = context.SELECTEDOPERATORS.physicaladdress;
    context.ADDOPERATOR.physical_address2 = context.SELECTEDOPERATORS.physicaladdress2;

    context.ADDOPERATOR.bankname_id = context.SELECTEDOPERATORS.bankname_id;
    context.ADDOPERATOR.accounttype_id = context.SELECTEDOPERATORS.bankaccounttype_id;
    context.ADDOPERATOR.account_number = context.SELECTEDOPERATORS.bankaccount_number;



    context.getVehiclesbyOperatorId(context,context.SELECTEDOPERATORS.taxi_operator_id);


    });

    $("#wizard").data("taxioperatoradded", false);

    //$('.selectpicker1').selectpicker('render');
    $("#wizard").smartWizard({
      selected: 0,
      theme: "default",
      transitionEffect: "",
      keyNavigation: false,
      transitionSpeed: 0,
      cycleSteps: true,
      useURLhash: false,
      showStepURLhash: false,
      toolbarSettings: {
        toolbarPosition: "bottom",
      },
    });

    $("#wizard").data("context", this.context);
    $("#wizard").on("leaveStep", function (
      e,
      anchorObject,
      stepNumber,
      stepDirection
    ) {


      /*  DEBUG PURPOSES */
      // return true;

      const context = $("#wizard").data("context");
      const currentstep = stepNumber + 1;
      const res = $('form[name="form-wizard"]')
        .parsley()
        .validate("step-" + currentstep);
      
        if (stepDirection == "backward") return true;
        if (!res) return true //res //TEMP disabled for debug ;

      if (context.hacksmartwizard) return true;

      $(".sw-btn-next").text("Next");

      if (currentstep === 1 && stepDirection == "forward") {
        if (!context.isIdNumberValid) return false;
        else {

          context.ADDOPERATOR.date_of_birth = context.$Toolkit.ConvertToServerDate(
            $("#date_of_birth").datepicker("getDate")
          );


          debugger;

          context.ADDOPERATOR.debit_date = context.$Toolkit.ConvertToDebitDateDate(
            $("#debtidate").datepicker("getDate")
          );

          if (!context.ADDOPERATOR.debit_date) {
            context.$Notify.Error("Please Enter Debit Date", "");
            return false;
          }


      context.ADDOPERATOR.postal_suburb_id = parseInt($("#selectPostalSuburb").val());
      context.ADDOPERATOR.physical_suburb_id = parseInt($("#selectPhysicalSuburb").val());

      context.BANKID = context.ADDOPERATOR.bankname_id.bankid;
      context.ACCOUNTYPE = context.ADDOPERATOR.accounttype_id.code;

      debugger;
      var debtidate = ""
      var CollectionDay = ""
      
      if($("#debtidate").datepicker("getDate").getDate().length > 1)
      {
        CollectionDay = $("#debtidate").datepicker("getDate").getDate()
      }
      else
      {
        CollectionDay = "0" + $("#debtidate").datepicker("getDate").getDate()
      }

      var debtidate = context.$Toolkit.ConvertToDebitDateDate(
            $("#debtidate").datepicker("getDate")
          );
      
      context.CREATECONTRACT.TaxiOperatorId = context.ADDOPERATOR.taxi_operator_id;
      //context.CREATECONTRACT.SystemUserId = context.ADDOPERATOR.taxi_operator_id;
      context.CREATECONTRACT.CollectionDay =  CollectionDay;
      context.CREATECONTRACT.DebtorName = context.ADDOPERATOR.firstname + " " + context.ADDOPERATOR.surname;
      context.CREATECONTRACT.DebtorId = context.ADDOPERATOR.id_number;
      context.CREATECONTRACT.DebtorPhoneNumber =  context.ADDOPERATOR.mobilenumber;
      context.CREATECONTRACT.DebtorAccountNumber = context.ADDOPERATOR.account_number;
      context.CREATECONTRACT.DebtorAccountType = context.ACCOUNTYPE;
      context.CREATECONTRACT.DebtorBankId = context.BANKID;
      context.CREATECONTRACT.DebtorBranchNumber = context.ADDOPERATOR.bankname_id.universalbranchcode;

      
      context.CREATECONTRACT.StartDate = debtidate;
      //context.CREATECONTRACT.ClientReference = ClientId


      context.ADDOPERATOR.bankname_id = context.ADDOPERATOR.bankname_id.id;
      context.ADDOPERATOR.accounttype_id = context.ADDOPERATOR.accounttype_id.id;

        }
      }

      if (currentstep === 1 && stepDirection == "forward") {
 
        $(".sw-btn-next").text("Submit");
      }

      if (currentstep === 2 && stepDirection == "forward") {
        
        const dropzone_uploads = context.GETUPLOADEDFILES;
        const fileuploaded = [];

        if (dropzone_uploads.length > 0) {
          var failedvalidation = false;
          dropzone_uploads.forEach((element) => {
            if (element.customType === 0) {
              failedvalidation = true;
              return false;
            }
            fileuploaded.push({
              customType: Number(element.customType),
              fileId: element.fileId,
            });
          });
          if (failedvalidation) {
            context.$Notify.Error("Please Select Document Type.", "");
            return false;
          }
        }

        context.ADDOPERATOR.dropzonefiles = fileuploaded;

          context.$Notify
          .YesNo(false, { context, item: context.ADDOPERATOR })
          .then(value => {
            if (value) {
              $(".sw-btn-next")
                .text("")
                .append('<i class="fas fa-spinner fa-spin"></i>')
                .attr("disabled", "disabled");
              value.router = value.context.$router;
              value.context.$store
                .dispatch("fleetmanagement/addBooking", {
                  AddModel: value.item,
                  router: value.context.$router
                })
                .then((results) => {
                  value.context.BOOKINGNUMBER = results.data[0].booknumber;
                  value.context.CLIENTID = results.data[0].clients_id;
                  value.context.CREATECONTRACT.ClientReference = results.data[0].clients_id.toString();
                  value.context.PREVIOUSNUCONTRACT = results.data[0].nupaycontractref;

                  debugger;
                  
                  value.context.CREATECONTRACT.CollectionAmount =  results.data[0].newdebitamount;
                  
                  value.context.CANCELCONTRACT.mandateID =  results.data[0].mandateid_;
                  value.context.CANCELCONTRACT.contractReference =  results.data[0].nupaycontractref;
                  value.context.CANCELCONTRACT.clientReference =  results.data[0].clients_id.toString();

              
                  value.context.REVIOUSCONTRACT = results.data[0].nupaycontractref;

                  value.context.getBookingByTaxiAssId(value.context,value.context.taxiassociationid);
                  
                   value.context.hacksmartwizard = true;
            $       ("#wizard").smartWizard("next");
                  $(".sw-btn-next")
                    .text("Done")
                    .attr("disabled", "disabled");
                    $(".sw-btn-prev")
                    .attr("disabled", "disabled");
                })
                .catch(error => {
                  $(".sw-btn-next")
                    .text("Submit")
                    .removeAttr("disabled", "disabled");

                  value.context.$Notify.Error(error.data[0].message, "");
                })
                .finally(() => {});
            }
          });

          return false;
      }

      return res;
    });

    var date = new Date();
    date.setDate(date.getDate() - 43000);
    console.log(date);


    $("#date_of_birth").datepicker({
      todayHighlight: false,
      autoclose: true,
      startDate: date,
    });

    // $(".debtidate").datepicker({
    //   todayHighlight: false,
    //   autoclose: true,
    // });
  
    $("#debtidate").datepicker({
      todayHighlight: false,
      autoclose: true,
    });

    function calculateDivider() {
      var dividerValue = 4;
      if ($(this).width() <= 480) {
        dividerValue = 1;
      } else if ($(this).width() <= 767) {
        dividerValue = 2;
      } else if ($(this).width() <= 980) {
        dividerValue = 3;
      }
      return dividerValue;
    }

    var handleIsotopesGallery = function () {
      var container = $("#gallery");
      var dividerValue = calculateDivider();
      var containerWidth = $(container).width();
      var columnWidth = containerWidth / dividerValue;
      $(container).isotope({
        resizable: true,
        masonry: {
          columnWidth: columnWidth,
        },
      });

      $(window).smartresize(function () {
        var dividerValue = calculateDivider();
        var containerWidth = $(container).width();
        var columnWidth = containerWidth / dividerValue;
        $(container).isotope({
          masonry: {
            columnWidth: columnWidth,
          },
        });
      });

      var $optionSets = $("#options .gallery-option-set"),
        $optionLinks = $optionSets.find("a");

      $optionLinks.click(function () {
        var $this = $(this);
        if ($this.hasClass("active")) {
          return false;
        }
        var $optionSet = $this.parents(".gallery-option-set");
        $optionSet.find(".active").removeClass("active");
        $this.addClass("active");

        var options = {};
        var key = $optionSet.attr("data-option-key");
        var value = $this.attr("data-option-value");
        value = value === "false" ? false : value;
        options[key] = value;
        $(container).isotope(options);
        return false;
      });
    };

    // $("#masked-input-phone").mask("(082) 686-9833");

    //FormPlugins.init();
    //Highlight.init();

    //     $(function(){
    //    $(".dropdown-menu").on('click', 'a', function(){
    //        $(this).parents('.dropdown').find('button').text($(this).text());
    //    })
    // });
  },
};
</script>

	

<style scoped>
</style>