<template>
  <div id="dropzone">
    <div class="dz-message needsclick">
      Drop files
      <b>here</b> or
      <b>click</b> to upload.
      <br />
      <span class="dz-note needsclick">Upload Supporting document.</span>
    </div>
  </div>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
const { mapGetters, mapActions } = createNamespacedHelpers("fileupload");
const config = require("../../configurations/configbase");

export default {
  name: "DropZoneControl",
  props: {
    SessionID: {
      type: String,
      required: true,
    },
    ClearData: {
      type: Boolean,
      required: true,
    },
    acceptedFiles: {
      type: String,
      required: false,
    },
    maxFiles: {
      type: Number,
      required: false,
    },
    resizeWidth: {
      type: Number,
      required: false,
    },
    resizeHeight: {
      type: Number,
      required: false,
    },
  },
  beforeMount() {
    if (this.ClearData) {
      this.$store
        .dispatch("fileupload/clearall", {
          router: this.$router,
        })
        .finally(() => {});
    }
  },
  data: function () {
    return {
      context: this,
      USERPROFILE: this.$store.getters["auth/USERPROFILE"],
      actionURL: config.default.BASE_URL(),
      SYSTEMUSERID: this.$store.getters["auth/USERPROFILE"].system_usersid,
    };
  },
  components: {},
  computed: {},
  methods: {
    ...mapActions(["added", "delete"]),
  },
  mounted() {
    $("#dropzone").data("context", this.context);
    var myDropzone = new Dropzone("#dropzone", {
      url:
        this.actionURL +
        "/fileupload/" +
        this.USERPROFILE.system_usersid +
        "/" +
        this.SessionID,
      acceptedFiles: this.acceptedFiles,
      addRemoveLinks: true,
      resizeWidth: this.resizeWidth,
      resizeHeight: this.resizeHeight,
      resizeMethod: "contain",
      maxFiles: this.maxFiles,
    });
    myDropzone.on("success", function (file, { fileId, customType }) {
        //
        const context = $("#dropzone").data("context");
        file.fileId = fileId;
        file.sessionID = context.SessionID;
        file.customType = 0;
        /* Maybe display some more file information on your page */

        //context.$emit("fileadded", file);
        context.$store
          .dispatch("fileupload/added", {
            router: context.$router,
            filecontent: file,
          }).then(() =>{
            const context = $("#dropzone").data("context");
            context.$emit("fileadded", file);
          })
          .catch((error) => {
            console.log(error);
          })
        
        
      }).on("removedfile", function (file) {
        const context = $("#dropzone").data("context");

        context.$store
          .dispatch("fileupload/delete", {
            router: context.$router,
            id: file.fileId,
            deletedby: context.SYSTEMUSERID,
          }).then(() =>{
            context.$emit("removedfile", file);
          })
          .catch((error) => {
            console.log(error);
          })

      });

    $("#dropzone").addClass("dropzone");
    $("#dropzone").addClass("needsclick");
    $(document).data("myDropzone", myDropzone);
  },
};
</script>

<style scoped>
</style>

