<template>
  <div class="home">
    <!-- begin #page-container -->
    <!-- begin #header -->
    <headerComp
      :AddTaxiOperatorDropDownControl="true"
      :AddTopSearchControl="true"
      :AddTopNotificationControl="true"
      :isAdminUser="USERPROFILE.is_system_admin"
    />
    <!-- begin #header -->
    <!-- end page container -->

    <!-- begin #sidebar -->
    <sidebarComp
      :USERROLES="ROLES"
      :USERPROFILE="USERPROFILE"
      :ActiveSubMenu="'BookASeat'"
      :ActiveMenu="'AddOperator'"
    />
    <!-- end #sidebar -->

    <!-- begin #content -->
    <div id="content" class="content">
      <!-- begin page-header -->
      <headerpagebreadcrumb
        pageheaderTitle="Add Operator"
        pageheaderdescription
        breadcrumbitem1="Home"
        breadcrumbitem2="Book-A-Seat"
        breadcrumbitem3="Add Operator"
      />
      <!-- end page-header -->

      <!-- begin #Main Body Template -->

      <!-- begin row -->

      <div class="row">
        <!-- begin col-12 -->
        <div class="col-lg-12">
          <!-- begin wizard-form -->
          <form action="/" method="POST" name="form-wizard" class="form-control-with-bg">
            <!-- begin wizard -->
            <div id="wizard">
              <!-- begin wizard-step -->
              <ul>
                <li class="col-md-3 col-sm-4 col-6">
                  <a href="#step-1">
                    <span class="number">1</span>
                    <span class="info text-ellipsis">
                      Personal Info
                      <small class="text-ellipsis">Name, Address, ID No and DOB</small>
                    </span>
                  </a>
                </li>
                <li class="col-md-3 col-sm-4 col-6">
                  <a href="#step-3">
                    <span class="number">3</span>
                    <span class="info text-ellipsis">
                      Completed
                      <small class="text-ellipsis">Complete Registration</small>
                    </span>
                  </a>
                </li>
              </ul>
              <!-- end wizard-step -->
              <!-- begin wizard-content -->
              <div>
                <!-- begin step-1 -->
                <div id="step-1">
                  <!-- begin fieldset -->
                  <fieldset>
                    <!-- begin row -->
                    <div class="row">
                      <div class="col-md-8 offset-md-2">
                        <legend
                          class="no-border f-w-700 p-b-0 m-t-0 m-b-20 f-s-16 text-inverse"
                        >Enter Your Personal Details</legend>


                          <!-- end form-group -->
                          <div class="form-group row m-b-10">
                          <label class="col-md-3 col-form-label text-md-right">
                           Select TAMS Operator
                            <span class="text-danger"></span>
                          </label>
                          <div class="col-md-6">
                            <select
                                  class="form-control"
                                  id="selecttamsops"
                                  data-parsley-required="false"
                                  data-parsley-group="step-1"
                                  v-model="ADDSYSTEMUSER.tams_operator_id"
                                >
                                  <option
                                  v-for="(item,index) in GETDRIVERSBYASSID"
                                  :key="item.taxi_operator_id"
                                  :value="index"
                                  >{{item.firstname + " " + item.surname + '-(' + item.id_number + ')'}}</option>
                                </select>
                          </div>
                        </div>
                        <!-- end form-group -->


                        <!-- begin form-group -->
                        <div class="form-group row m-b-10">
                          <label class="col-md-3 col-form-label text-md-right">
                            Mobile No
                            <span class="text-danger">*</span>
                          </label>
                          <div class="col-md-6">
                            <div class="input-group">
                              <div class="input-group-prepend">
                                <span class="input-group-text">@</span>
                              </div>
                              <input
                                type="Number"
                                id="usernameId"
                                data-parsley-maxlength="10"
                                placeholder="Enter Mobile No"
                                :class="['form-control',{'is-invalid': isUserNameExists, 'is-valid': !isUserNameExists}]"
                                v-model.lazy="USERNAME"
                              />
                              <div class="input-group-append">
                                <button type="button" @click="SendOTPAction(context)" class="btn btn-outline-success">Send OTP</button>
                            </div>
                              <div
                                class="invalid-tooltip"
                              >Please choose a unique and valid Mobile No.</div>
                              <div class="valid-tooltip">Looks good!</div>
                            </div>
                          </div>
                        </div>
                        <!-- end form-group -->
                      </div>

                      <!-- begin col-8 -->
                      <div class="col-md-8 offset-md-2">
                        <legend class="no-border f-w-700 p-b-0 m-t-0 m-b-20 f-s-16 text-inverse"></legend>
                  

                   

                        <!-- begin form-group -->
                        <div class="form-group row m-b-10">
                          <label class="col-md-3 col-form-label text-md-right">
                            First Name
                            <span class="text-danger">*</span>
                          </label>
                          <div class="col-md-6">
                            <input
                              type="text"
                              name="firstname"
                              autocomplete="off"
                              data-parsley-group="step-1"
                              data-parsley-required="true"
                              class="form-control"
                              v-model="ADDSYSTEMUSER.firstname"
                            />
                          </div>
                        </div>
                        <!-- end form-group -->

                        <!-- begin form-group -->
                        <div class="form-group row m-b-10">
                          <label class="col-md-3 col-form-label text-md-right">
                            Surname
                            <span class="text-danger">*</span>
                          </label>
                          <div class="col-md-6">
                            <input
                              type="text"
                              name="surname"
                              autocomplete="off"
                              data-parsley-group="step-1"
                              data-parsley-required="true"
                              class="form-control"
                              v-model="ADDSYSTEMUSER.surname"
                            />
                          </div>
                        </div>
                        <!-- end form-group -->
                        <div class="form-group row m-b-10">
                          <label class="col-md-3 col-form-label text-md-right">
                            ID Number
                            <span class="text-danger"></span>
                          </label>
                          <div class="col-md-6">
                            <input
                              data-parsley-group="step-1"
                              data-parsley-required="false"
                              type="number"
                              class="form-control"
                               data-parsley-maxlength="13"
                              id="masked-input-tid"
                              v-model="ADDSYSTEMUSER.idnumber"
                            />
                          </div>
                        </div>
                        <!-- end form-group -->

                          <!-- end form-group -->
                          <div class="form-group row m-b-10">
                          <label class="col-md-3 col-form-label text-md-right">
                           Bank Name
                            <span class="text-danger"></span>
                          </label>
                          <div class="col-md-6">
                            <select
                                  class="form-control"
                                  id="selectoperatingroute"
                                  data-parsley-required="false"
                                  data-parsley-group="step-1"
                                  v-model="ADDSYSTEMUSER.BankId"
                                >
                                  <option
                                    v-bind:key="item.id"
                                    v-for="item in BANKS"
                                    :value="item.id"
                                  >{{item.name}}</option>
                                </select>
                          </div>
                        </div>
                        <!-- end form-group -->

                        <!-- begin form-group -->
                        <div class="form-group row m-b-10">
                          <label class="col-md-3 col-form-label text-md-right">
                            Account No
                            <span class="text-danger"></span>
                          </label>
                          <div class="col-md-6">
                            <input
                              type="Number"
                              name="surname"
                              autocomplete="off"
                              data-parsley-group="step-1"
                              data-parsley-required="false"
                              class="form-control"
                              v-model="ADDSYSTEMUSER.AccountNo"
                            />
                          </div>
                        </div>
                        <!-- end form-group -->

                       
                      
                      </div>
                      <!-- end col-8 -->
                    </div>
                    <!-- end row -->
                  </fieldset>
                  <!-- end fieldset -->
                </div>
                <!-- end step-1 -->
              

                <!-- begin step-3 -->
                <div id="step-3">
                  <div class="jumbotron m-b-0 text-center">
                    <h2 class="text-inverse">Operator Registered Successfully</h2>
                    <p class="m-b-30 f-s-16 ">
                      Password has been sent to the New User via SMS, Please check your phone
                    </p>
                    <p>
                      <a href="#" class="btn btn-primary btn-lg hide">Proceed to User Profile</a>
                    </p>
                  </div>
                </div>
                <!-- end step-3 -->
              </div>
              <!-- end wizard-content -->
            </div>
            <!-- end wizard -->
          </form>
          <!-- end wizard-form -->
        </div>
        <!-- end col-12 -->
      </div>

      <!-- end row -->

      <!-- begin #Main Body Template -->

         <!-- #OTP Modal -->
         <div class="modal fade" id="OTPModal" data-bs-keyboard="false" data-bs-backdrop="static">
          <div class="modal-dialog">
            <div class="modal-content">
              <div class="modal-header"></div>
              <div class="modal-body">
                <p class="modal-title">One Time Verification Sent To The Operator</p>
                <br />
                <!-- begin form-group -->
                <div class="form-group row m-b-10">
                  <label class="col-md-4 col-form-label text-md-right">
                    OTP Number:
                    <span class="text-danger">*</span>
                  </label>
                  <div class="col-md-6">
                    <input
                      type="text"
                      class="form-control"
                      v-model="VERIFIY.one_time_pin"
                    />
                  </div>
                </div>
                <!-- end form-group -->
              </div>
              <div class="modal-footer text-center">
                <a
                  href="javascript:;"
                  class="btn btn-default m-r-5"
                  data-dismiss="modal"
                  >CLOSE</a
                >
                <a
                  href="javascript:;"
                  @click="SendOPT(context)"
                  class="btn btn-warning m-r-5"
                  >RESEND OTP</a
                >

                <button
                  type="button"
                  @click="verifyUserPin(context, VERIFIY)"
                  class="btn btn-success m-r-5"
                  :disabled="$wait.is('verifyUserPin')"
                >
                  <i
                    class="fa fa-search fa-fw"
                    v-show="!$wait.is('verifyUserPin')"
                  ></i>
                  <i
                    class="
                      fa fa-spinner fa-spin
                      t-plus-1
                      text-white
                      fa-fw fa-lg
                    "
                    v-show="$wait.is('verifyUserPin')"
                  ></i>
                  VERIFY
                </button>
              </div>
            </div>
          </div>
        </div>
        <!-- #OTP Modal end -->

      <!-- end #content -->
    </div>

    <!-- begin scroll to top btn -->
    <pagescroller />
    <!-- end scroll to top btn -->
  </div>
</template>

<script>
// @ is an alias to /src
//import LoginComp from '@/components/LoginComp.vue'

import CapturePhotosControl from "@/components/common/CapturePhotosControl.vue";
import headerComp from "@/components/common/header.vue";
import headerpagebreadcrumb from "@/components/common/pageheader-breadcrumb.vue";
import pageloader from "@/components/common/pageloader.vue";
import pagescroller from "@/components/common/pagescroller.vue";
import sidebarComp from "@/components/common/sidebar.vue";

import { createNamespacedHelpers } from "vuex";
const { mapGetters, mapActions } = createNamespacedHelpers("tpayauth");

export default {
  name: "AddSystemUser",
  data: function() {
    return {
      BANKS:[],
      TPAYPROFILE: this.$store.getters["auth/TPAYPROFILE"],
      isUserNameExists: true,
      ISMOBILEVERIFIED:false,
      GETDRIVERSBYASSID:[],
      OPT:"",
      USERNAME: "",
      ROLES: this.$store.getters["auth/GETUSERROLES"],
      USERPROFILE: this.$store.getters["auth/USERPROFILE"],
      SERPROFILE: this.$store.getters["auth/USERPROFILE"],
      USERID: this.$store.getters["auth/USERPROFILE"].system_usersid,
      taxi_association_id: this.$store.getters["auth/ASSIGNEDTAXIASSOCIATION"]
        .id,
      isSystemAdmin: this.$store.getters["auth/USERPROFILE"].is_system_admin,
      context: this,
      selectedUserGroupId: 0,
      GETALLUSERGROUPS: [],
      resolutionWidth: undefined,
      resolutionHeight: undefined,
      hacksmartwizard: false,
      ADDSYSTEMUSER: {
        firstname: "",
        surname : "",
        mobilenumber: "",
        idnumber: "",
        tams_operator_id: 0,
        BankId: 0,
        AccountNo: "",
    },
    VERIFIY: {
        user_id: "",
        webpushtoken: "",
        one_time_pin: "",
      },
    };
  },
  beforeCreate: function() {
    document.body.classList.remove("bg-white");
  },
  components: {
    headerpagebreadcrumb,
    headerComp,
    sidebarComp,
    pagescroller,
    pageloader,
    CapturePhotosControl
  },
  computed: {
    ...mapGetters(["GETALLTITLES", "GETALLGENDERS"])
  },
  watch: {
    USERNAME: function(val) {
      if (val.toString().length > 3) {
        this.context.$store
          .dispatch("tpayauth/MobileNumberExists", {
            mobilenumber: val
          })
          .then(rb => {
            
            this.isUserNameExists = false;
            //this.isUserNameExists = rb.Status;
          });
      }
    }
  },
  methods: {
    ...mapActions([
      "getall",
      "getById",
      "getByTaxiAssId",
      "delete",
      "edit",
      "add",
      "restoreDeleted"
    ]),
    SendOTPAction: (context) => {
      //context.ISMOBILEVERIFIED = true;
      context.ADDSYSTEMUSER.mobilenumber = context.USERNAME
      context.SendOPT(context)
      $('#OTPModal').modal('show');
    },
    getallbyid: (context, taxiassociationid) => {
  

      context.$store.dispatch("TO/getTaxiOperatorsByAssIdSummary", {
        router: context.$router,
          taxiassociation_id: taxiassociationid,
          skip: 0,
          len: 10000,
        }).then((data) => {
          
          context.GETDRIVERSBYASSID = data;
        })
        .catch((error) => {
          console.log("Driver errors: ", error);
        })
        .finally(() => {
        $("#selecttamsops").select2({
          multiple: false,
          placeholder: "Select Operators",
          allowClear: false,
        }).on('change', function(){
          // ...
          //alert($("#selallranks").select2("data")[0])
          
          const context = $("#wizard").data("context");

          var item = context.GETDRIVERSBYASSID[$("#selecttamsops").select2("data")[0].id]

          

      
          context.ADDSYSTEMUSER.tams_operator_id = item.taxi_operator_id;
          context.ADDSYSTEMUSER.firstname = item.firstname
          context.ADDSYSTEMUSER.surname = item.surname
          context.ADDSYSTEMUSER.mobilenumber = item.mobilenumber
          context.ADDSYSTEMUSER.idnumber =item.id_number
          context.USERNAME = item.mobilenumber
          

          //alert(context.ADDSYSTEMUSER.tams_operator_id)
          
        });

      });
    },
    verifyUserPin: (context, item) => {
      if(item.one_time_pin !== context.OPT){
          context.$Notify.Error("Wrong OPT", "");
        } else {
          $('#OTPModal').modal('hide');
          context.ISMOBILEVERIFIED = true
          $("#wizard").smartWizard("next");
        }
    },
    init: file => {},
    GETAllBANKS: context => {
      context.$store
        .dispatch("tpay_systemuser/GETBANKS", {
        })
        .then((rb) => {

          context.BANKS = rb.Data
          
        });
    },
    SendOPT: (context) => {
      context.$store
        .dispatch("tpayauth/GetOPT", {
          mobilenumber : context.ADDSYSTEMUSER.mobilenumber
        })
        .then((rb) => {
          context.OPT = rb.Data.otpnumber;
          //context.BANKS = rb.Data
          
        });
    },
  },
  mounted: function() {
    //this.get(this.context);

    App.init();

    this.getallbyid(this.context, this.taxi_association_id);
    this.GETAllBANKS(this.context);

    $("#wizard").smartWizard({
      selected: 0,
      theme: "default",
      transitionEffect: "slide",
      transitionSpeed: 400,
      useURLhash: false,
      showStepURLhash: false,
      toolbarSettings: {
        toolbarPosition: "bottom"
      }
    });

    $("#wizard").data("context", this.context);

    $("#wizard").on("leaveStep", function(
      e,
      anchorObject,
      stepNumber,
      stepDirection
    ) {
     
      const context = $("#wizard").data("context");
      const currentstep = stepNumber + 1;
      var res = $('form[name="form-wizard"]')
        .parsley()
        .validate("step-" + currentstep);

        if(context.hacksmartwizard)
        return true;


      $(".sw-btn-next").text("Next");

      if (currentstep === 1 && stepDirection == "forward") {
        if (!res){
          return res;
        }
        if (context.isUserNameExists){
          return false;
        }else{

          // if(!context.ISMOBILEVERIFIED){
          //   context.$Notify.Error("Please verify the mobile number via OPT", "");
          //   return false;
          // } else {
          //   $(".sw-btn-next").text("Submit");
          // }    
          $(".sw-btn-next").text("Submit");
        }
      }

      if (currentstep === 1 && stepDirection == "forward") {

           const context = $("#wizard").data("context");

           context.ADDSYSTEMUSER.mobilenumber = context.USERNAME

           

        context.$Notify
          .YesNo(false, { context: context, item: context.ADDSYSTEMUSER })
          .then(value => {
            if (value) {
              $(".sw-btn-next")
                .text("")
                .append('<i class="fas fa-spinner fa-spin"></i>')
                .attr("disabled", "disabled");
              value.router = value.context.$router;
              value.context.$store
                .dispatch("tpay_systemuser/RegisterOperators", value.item)
                .then((parsedResult) => {
                  if(!parsedResult.Status){
                    $(".sw-btn-next")
                    .text("Submit")
                    .removeAttr("disabled", "disabled");
                    value.context.$Notify.Error(parsedResult.Message, "Failed");
                    return
                  }
                   value.context.hacksmartwizard = true;
            $("#wizard").smartWizard("next");
                  $(".sw-btn-next")
                    .text("Done")
                    .attr("disabled", "disabled");
                })
                .catch(error => {
                  $(".sw-btn-next")
                    .text("Submit")
                    .removeAttr("disabled", "disabled");

                  value.context.$Notify.Error(parsedResult.Message, "");
                })
                .finally(() => {});
            }
          });

        return false;
      }

      return res;
    });

  }
};
</script>

<style scoped>
</style>

