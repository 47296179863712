<template>
  <div class="home">
    <!-- begin #page-container -->
    <!-- begin #header -->
    <headerComp
      :AddTaxiOperatorDropDownControl="true"
      :AddTopSearchControl="true"
      :AddTopNotificationControl="true"
      :isAdminUser="USERPROFILE.is_system_admin"
      v-on:init="init"
    />
    <!-- begin #header -->
    <!-- end page container -->

    <!-- begin #sidebar -->
    <sidebarComp
      :USERROLES="ROLES"
      :USERPROFILE="USERPROFILE"
      :ActiveSubMenu="'LoanApplication'"
      :ActiveMenu="'Loans'"
    />
    <!-- end #sidebar -->

    <!-- begin #content -->
    <transition name="bounce">
      <div id="content" class="content" v-show="USERPROFILE.taxi_associations_id">
        <!-- begin page-header -->
        <headerpagebreadcrumb
          pageheaderTitle="Loan Application - Dashboard"
          pageheaderdescription
          breadcrumbitem1="Home"
          breadcrumbitem2="Loan Application"
          breadcrumbitem3="Dashboard"
        />
        <!-- end page-header -->
        <!-- begin #Main Body Template -->

        <!-- begin row -->
        <div class="row">
           <!-- begin col-3 -->
           <div class="col-lg-3 col-md-6">
            <div class="widget widget-stats bg-white text-inverse">
              
             <div class="stats-content">
                <div class="stats-title">Yearly Total Loans</div>
                <div class="stats-number">{{GETDASHBOARDCARDS.yearlycommission | newcurrencywithSymbol}}</div>
                 <div class="stats-desc"></div>
              </div>
            </div>
          </div>
          <!-- end col-3 -->
            <!-- begin col-3 -->
           <div class="col-lg-3 col-md-6">
            <div class="widget widget-stats bg-white text-inverse">
             
              <div class="stats-content">
                <div class="stats-title">Quarterly Total Loans</div>
                <div class="stats-number">{{GETDASHBOARDCARDS.quarterlycommission | newcurrencywithSymbol}}</div>
                 <div class="stats-desc"></div>
              </div>
            </div>
          </div>
          <!-- end col-3 -->
           <!-- begin col-3 -->
           <div class="col-lg-3 col-md-6">
            <div class="widget widget-stats bg-white text-inverse">
             
              <div class="stats-content">
                <div class="stats-title">Last Month's Total Loans</div>
                <div class="stats-number">{{GETDASHBOARDCARDS.lastmonthcommission | newcurrencywithSymbol}}</div>
                 <div class="stats-desc"></div>
              </div>
            </div>
          </div>
          <!-- end col-3 -->
           <!-- begin col-3 -->
           <div class="col-lg-3 col-md-6">
            <div class="widget widget-stats bg-white text-inverse">
             
              <div class="stats-content">
                <div class="stats-title">This Month's Total Loans</div>
                <div class="stats-number">{{GETDASHBOARDCARDS.thismonthcommission | newcurrencywithSymbol}}</div>
                 <div class="stats-desc"></div>
              </div>
            </div>
          </div>
          <!-- end col-3 -->
           

           <!-- begin col-3 -->
           <div class="col-lg-3 col-md-6">
            <div class="widget widget-stats bg-white text-inverse">
              <div class="stats-icon stats-icon-square bg-gradient-orange">
                <i class="ion-ios-man"></i>
              </div>
              <div class="stats-content">
                <div class="stats-title">Total Approved Loans</div>
                <div class="stats-number">{{GETDASHBOARDCARDS.totalclients}}</div>
                <div class="stats-desc"></div>
              </div>
            </div>
          </div>
          <!-- end col-3 -->
           <!-- begin col-3 -->
           <div class="col-lg-3 col-md-6">
            <div class="widget widget-stats bg-white text-inverse">
              <div class="stats-icon stats-icon-square bg-gradient-orange">
                <i class="fas fa-car"></i>
              </div>
              <div class="stats-content">
                <div class="stats-title">Total Rejected Loans</div>
                <div class="stats-number">{{GETDASHBOARDCARDS.totalvehicles }}</div>
                <div class="stats-desc"></div>
              </div>
            </div>
          </div>
          <!-- end col-3 -->
           <!-- begin col-3 -->
           <div class="col-lg-3 col-md-6">
            <div class="widget widget-stats bg-white text-inverse">
              <div class="stats-icon stats-icon-square bg-gradient-orange">
                <i class="fas fa-registered"></i>
              </div>
              <div class="stats-content">
                <div class="stats-title">Total Arrears</div>
                <div class="stats-number"> {{GETDASHBOARDCARDS.totalarrears | currencywithSymbol}}</div>
                <div class="stats-desc"></div>
              </div>
            </div>
          </div>
          <!-- end col-3 -->
           <!-- begin col-3 -->
           <div class="col-lg-3 col-md-6">
            <div class="widget widget-stats bg-white text-inverse">
              <div class="stats-icon stats-icon-square bg-gradient-red">
                <i class="fas fa-registered"></i>
              </div>
              <div class="stats-content">
                <div class="stats-title">Total Bad Debt</div>
                <div class="stats-number">{{GETDASHBOARDCARDS.totalbaddebt | currencywithSymbol}}</div>
                <div class="stats-desc"></div>
              </div>
            </div>
          </div>
          <!-- end col-3 -->
  
        </div>

        <!-- end row -->

         <!-- begin row -->
        <div class="row">
          <!-- begin col-8 -->
          <div class="col-xl-12">

                <!-- begin panel -->
            <div class="panel panel-inverse" data-sortable-id="index-1">
              <div class="panel-heading">
                <div class="panel-heading-btn">
                  <a
                    href="javascript:;"
                    class="btn btn-xs btn-icon btn-circle btn-default"
                    data-click="panel-expand"
                  >
                    <i class="fa fa-expand"></i>
                  </a>
                  <a
                    href="javascript:;"
                    class="btn btn-xs btn-icon btn-circle btn-success"
                    data-click="panel-reload"
                  >
                    <i class="fa fa-redo"></i>
                  </a>
                  <a
                    href="javascript:;"
                    class="btn btn-xs btn-icon btn-circle btn-warning"
                    data-click="panel-collapse"
                  >
                    <i class="fa fa-minus"></i>
                  </a>
                  <a
                    href="javascript:;"
                    class="btn btn-xs btn-icon btn-circle btn-danger"
                    data-click="panel-remove"
                  >
                    <i class="fa fa-times"></i>
                  </a>
                </div>
                <h4 class="panel-title">Loans</h4>
              </div>
              <div class="panel-body">
                <div id="interactive-chartOpbyAss" class="height-sm"></div>
              </div>
            </div>
            <!-- end panel -->
          </div>
         
        </div>
        <!-- end row -->


        <!-- begin row -->
        <div class="row">
          <!-- begin col-8 -->
          <div class="col-xl-8">

           <!-- begin panel -->
            <div class="panel panel-inverse" data-sortable-id="index-2">
              <div class="panel-heading">
                <div class="panel-heading-btn">
                  <a
                    href="javascript:;"
                    class="btn btn-xs btn-icon btn-circle btn-default"
                    data-click="panel-expand"
                  >
                    <i class="fa fa-expand"></i>
                  </a>
                  <a
                    href="javascript:;"
                    class="btn btn-xs btn-icon btn-circle btn-success"
                    data-click="panel-reload"
                  >
                    <i class="fa fa-redo"></i>
                  </a>
                  <a
                    href="javascript:;"
                    class="btn btn-xs btn-icon btn-circle btn-warning"
                    data-click="panel-collapse"
                  >
                    <i class="fa fa-minus"></i>
                  </a>
                  <a
                    href="javascript:;"
                    class="btn btn-xs btn-icon btn-circle btn-danger"
                    data-click="panel-remove"
                  >
                    <i class="fa fa-times"></i>
                  </a>
                </div>
                <h4 class="panel-title">Collection Rate</h4>
              </div>
              <div class="panel-body">
                <div id="donut-OperatorByGender" class="height-lg"></div>
              </div>
            </div>
            <!-- end panel -->



          </div>

         
          <!-- end col-8 -->
          <!-- begin col-8 -->
          <div class="col-xl-4">

              <!-- begin panel -->
            <div class="panel panel-inverse" data-sortable-id="index-6">
              <div class="panel-heading">
                <div class="panel-heading-btn">
                  <a
                    href="javascript:;"
                    class="btn btn-xs btn-icon btn-circle btn-default"
                    data-click="panel-expand"
                  >
                    <i class="fa fa-expand"></i>
                  </a>
                  <a
                    href="javascript:;"
                    class="btn btn-xs btn-icon btn-circle btn-success"
                    data-click="panel-reload"
                  >
                    <i class="fa fa-redo"></i>
                  </a>
                  <a
                    href="javascript:;"
                    class="btn btn-xs btn-icon btn-circle btn-warning"
                    data-click="panel-collapse"
                  >
                    <i class="fa fa-minus"></i>
                  </a>
                  <a
                    href="javascript:;"
                    class="btn btn-xs btn-icon btn-circle btn-danger"
                    data-click="panel-remove"
                  >
                    <i class="fa fa-times"></i>
                  </a>
                </div>
                <h4 class="panel-title">Top 5 Arrears</h4>
              </div>
              <div class="panel-body p-t-0">
                <div class="table-responsive">
                  <table class="table table-valign-middle">
                    <thead>
                      <tr>
                        <th>Full Name</th>
                        <th>Mobile No</th>
                        <th>Total Amount</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
					  v-for="(item,index) in GETOPERATORFINSTATS.top5arrears"
                    v-bind:key="item.id"
                    :class="index & 1 ? 'odd gradeA' : 'even'"
					  >
                        <td>{{item.operatorname}}</td>
                        <td>{{item.mobileno}}</td>
                        <td>{{item.outstandingbalance | newcurrencywithSymbol}}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <!-- end panel -->

              <!-- begin panel -->
            <div class="panel panel-inverse" data-sortable-id="index-10">
              <div class="panel-heading">
                <div class="panel-heading-btn">
                  <a
                    href="javascript:;"
                    class="btn btn-xs btn-icon btn-circle btn-default"
                    data-click="panel-expand"
                  >
                    <i class="fa fa-expand"></i>
                  </a>
                  <a
                    href="javascript:;"
                    class="btn btn-xs btn-icon btn-circle btn-success"
                    data-click="panel-reload"
                  >
                    <i class="fa fa-redo"></i>
                  </a>
                  <a
                    href="javascript:;"
                    class="btn btn-xs btn-icon btn-circle btn-warning"
                    data-click="panel-collapse"
                  >
                    <i class="fa fa-minus"></i>
                  </a>
                  <a
                    href="javascript:;"
                    class="btn btn-xs btn-icon btn-circle btn-danger"
                    data-click="panel-remove"
                  >
                    <i class="fa fa-times"></i>
                  </a>
                </div>
                <h4 class="panel-title">Top 5 Bad Debts</h4>
              </div>
              <div class="panel-body p-t-0">
                <div class="table-responsive">
                  <table class="table table-valign-middle">
                    <thead>
                      <tr>
						            <th>Full Name</th>
                        <th>Mobile No</th>
                        <th>Total Amount</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
					  v-for="(item,index) in GETOPERATORFINSTATS.top5baddebt"
                    v-bind:key="item.id"
                    :class="index & 1 ? 'odd gradeA' : 'even'"
					  >
                        <td>{{item.operatorname}}</td>
                        <td>{{item.mobileno}}</td>
                        <td>{{item.outstandingbalance | newcurrencywithSymbol}}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <!-- end panel -->
 

          </div>
          <!-- end col-8 -->
         
        </div>
        <!-- end row -->

      
        <!-- begin row -->

        <!-- begin #Main Body Template -->
      </div>
    </transition>
    <!-- end #content -->

    <!-- begin scroll to top btn -->
    <pagescroller />
    <!-- end scroll to top btn -->
  </div>
</template>

<script>
// @ is an alias to /src
//import LoginComp from '@/components/LoginComp.vue'

import statsCards from "@/components/charts/statsCards.vue";
import headerComp from "@/components/common/header.vue";
import headerpagebreadcrumb from "@/components/common/pageheader-breadcrumb.vue";
import pageloader from "@/components/common/pageloader.vue";
import pagescroller from "@/components/common/pagescroller.vue";
import sidebarComp from "@/components/common/sidebar.vue";

import { createNamespacedHelpers } from "vuex";
const { mapGetters, mapActions } = createNamespacedHelpers(
  "fleetmanagement_dashboard"
);

export default {
  name: "regionalchairman_dashboard",
  data: function() {
    return {
      message: "Some Message",
      ROLES: this.$store.getters["auth/GETUSERROLES"],
      USERPROFILE: this.$store.getters["auth/USERPROFILE"],
      taxiassociationid: this.$store.getters["auth/ASSIGNEDTAXIASSOCIATION"].id,
      GETDASHBOARDCARDS: {},
      GETOPERATORFINSTATS: {},
      GETOPERATORFINSTATSTOP5: {},
      GETALLTRANSACTIONS: [],
      ISINTERNALSYSTEMUSER: this.$store.getters["auth/ISINTERNALSYSTEMUSER"],
      USERID: this.$store.getters["auth/USERPROFILE"].system_usersid,
      context: this,
    };
  },
  beforeCreate: function() {
    document.body.classList.remove("bg-white");
  },
  components: {
    headerpagebreadcrumb,
    statsCards,
    headerComp,
    sidebarComp,
    pagescroller,
    pageloader
  },
  computed: {
    ...mapGetters(["", ""])
  },
  methods: {
    ...mapActions(["getdashboardcards","getallfueltransactions", "getoperatorfinstats"]),
    init: function() {},
    Del: (context,item) => {
      context.$Notify
          .YesNo(false, { context, item })
          .then(value => {
            if (value) {
              context.$wait.start("UpdateNextOfKinAction-" + value.item.id);
              const router = value.context.$router;
            var payload = {
              id: value.item.id,
              deletedby: value.context.USERID,
              router
            };
            value.context.$store
              .dispatch("fuelmanager_dashboard/DeleteFuelTransactions", payload)
              .then(parsedResult => {
                //const context = $(document).data("context");

               

                if(parsedResult.status){
                  value.context.GETALLTRANSACTIONS = value.context.GETALLTRANSACTIONS.filter(item => item.id !== value.item.id)
                value.context.$Notify.Success(parsedResult.message, "");
                value.item.is_active = false;
                context.$wait.end("UpdateNextOfKinAction-" + value.item.id);
                }
              })
              .catch(error => {
                context.$wait.end("UpdateNextOfKinAction-" + value.item.id);
              });
                  }
                });

     
    },
  },
  mounted: function() {
    $(document).data("context", this);
    App.init();

    const router = this.$router;

    var payload = {
      taxiassociationsId: this.taxiassociationid
    };

    this.getdashboardcards({ router, payload })
      .then(parsedResultOP => {
        //const context = $(document).data("context");

        debugger;

        this.GETDASHBOARDCARDS = parsedResultOP.data[0];

      
      })
      .catch(error => {
        //context.$wait.end("generatestatement");
      });


    var handleOperatorByGender = function(json_data) {
      "use strict";
      if ($("#donut-OperatorByGender").length !== 0) {
        const context = $(document).data("context");

        const colors = [
        COLOR_RED,
        COLOR_ORANGE,
        COLOR_GREEN,
        COLOR_AQUA,
        COLOR_BLUE,
        COLOR_PURPLE,
        COLOR_GREY,
        COLOR_BLACK,
        COLOR_BLACK
      ];

      debugger


        var operatorGenderData = [];
        

        
          operatorGenderData.push({
            label: "Expect- R" + json_data.expvsreceived[0].expected + "",
            data: parseFloat(json_data.expvsreceived[0].expected),
            color: colors[0]
          })

          operatorGenderData.push({
            label: "Received - R" + json_data.expvsreceived[0].income + "",
            data: parseFloat(json_data.expvsreceived[0].income),
            color: colors[2]
          })
      

        $.plot("#donut-OperatorByGender", operatorGenderData, {
          series: {
            pie: {
              innerRadius: 0.5,
              show: true,
              label: {
                show: true
              }
            }
          },
          grid: {
            hoverable: true,
            clickable: true
          },
          legend: {
            show: true
          }
        });
      }
	};
	


var handleTotalOpPerTaxiAssBarChart = function (json_data) {
'use strict';
	
	if ($('#interactive-chartOpbyAss').length !== 0) {

    const context = $(document).data("context");

    //var json_data = context.GETOPERATORFINSTATS;

  var data_dep = [];
  var data_pay = [];
  var data_30days = [];

  
   

  for(var i in json_data.days){
    data_dep.push(json_data.v_row_expected[i].commissionamount);
    data_pay.push(json_data.v_row_actual[i].commissionamount);
    data_30days.push(json_data.days[i].days);
  }
    
          
	
	var options = {
		chart: {
			height: 350,
			type: 'bar'
		},
		title: {
			text: 'Monthly Loans Last Year vs This Year',
			align: 'center'
		},
		plotOptions: {
			bar: {
				horizontal: false,
				columnWidth: '100%',
        endingShape: 'rounded',
        dataLabels: {
          position: 'top'
        }
			},
		},
		dataLabels: {
      enabled: true
		},
		stroke: {
			show: true,
			width: 2,
			colors: ['transparent']
		},
		colors: [COLOR_GREEN,COLOR_RED],
		series: [{
			name: 'This Year',
			data: data_dep
		}, {
			name: 'Last Year',
			data: data_pay
		}],
		xaxis: {
			categories: data_30days,
			axisBorder: {
				show: true,
				color: COLOR_SILVER_TRANSPARENT_5,
				height: 1,
				width: '100%',
				offsetX: 0,
				offsetY: -1
			},
			axisTicks: {
				show: true,
				borderType: 'solid',
				color: COLOR_SILVER,
				height: 6,
				offsetX: 0,
				offsetY: 0
			}
		},
		yaxis: {
			title: {
				text: 'Rands'
			}
		},
		fill: {
			opacity: 1
		},
		tooltip: {
			y: {
				formatter: function (val) {
					return + val
				}
			}
		}
	};
	
	var chart = new ApexCharts(
		document.querySelector('#interactive-chartOpbyAss'),
		options
	);

	chart.render();
	
	}
};

  

this.getoperatorfinstats({ router, payload })
      .then(parsedResultOP => {
        const context = $(document).data("context");

        debugger;

        context.GETOPERATORFINSTATS = parsedResultOP;

    // handleOperatorByAgeGroup();
		// handleVehicleWithNoOperatingLicense();
    handleTotalOpPerTaxiAssBarChart(parsedResultOP);
    handleOperatorByGender(parsedResultOP);

    // handleVehicleWithfinancechartdisk();

    
      })
      .catch(error => {
        //context.$wait.end("generatestatement");
      });
      
  }
};
</script>

<style>
@import "/assets/plugins/nvd3/build/nv.d3.css";
</style>