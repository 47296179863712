<template>
  <div class="home">
    <!-- begin #page-container -->
    <!-- begin #header -->
    <headerComp
      :AddTaxiOperatorDropDownControl="true"
      :AddTopSearchControl="true"
      :AddTopNotificationControl="true"
      :isAdminUser="USERPROFILE.is_system_admin"
      v-on:init="init"
    />
    <!-- begin #header -->
    <!-- end page container -->

    <!-- begin #sidebar -->
    <sidebarComp
      :USERROLES="ROLES"
      :USERPROFILE="USERPROFILE"
      :ActiveSubMenu="'BookASeat'"
      :ActiveMenu="'BookingDashboard'"
    />
    <!-- end #sidebar -->

    <!-- begin #content -->
    <transition name="bounce">
      <div id="content" class="content" v-show="USERPROFILE.taxi_associations_id">
        <!-- begin page-header -->
        <headerpagebreadcrumb
          pageheaderTitle="Book A Seat - Dashboard"
          pageheaderdescription
          breadcrumbitem1="Home"
          breadcrumbitem2="TPAY"
          breadcrumbitem3="Bookings Dashboard"
        />
        <!-- end page-header -->
        <!-- begin #Main Body Template -->

        <!-- begin row -->
        <div class="row">
           <!-- begin col-3 -->
           <div class="col-lg-3 col-md-6">
            <div class="widget widget-stats bg-white text-inverse">
              <div class="stats-icon stats-icon-square bg-gradient-orange">
                <i class="ion-ios-people"></i>
              </div>
              <div class="stats-content">
                <div class="stats-title">Total Commuters</div>
                <div class="stats-number">{{GETDASHBOARDCARDS.totalcommuters}}</div>

                <div class="stats-desc">Total Commuters</div>
              </div>
            </div>
          </div>
          <!-- end col-3 -->
           <!-- begin col-3 -->
           <div class="col-lg-3 col-md-6">
            <div class="widget widget-stats bg-white text-inverse">
              <div class="stats-icon stats-icon-square bg-gradient-orange">
                <i class="ion-ios-people"></i>
              </div>
              <div class="stats-content">
                <div class="stats-title">Total Operators</div>
                <div class="stats-number">{{GETDASHBOARDCARDS.totaloperators}}</div>

                <div class="stats-desc">Total Operators</div>
              </div>
            </div>
          </div>
          <!-- end col-3 -->
           <!-- begin col-3 -->
           <div class="col-lg-3 col-md-6">
            <div class="widget widget-stats bg-white text-inverse">
              <div class="stats-icon stats-icon-square bg-gradient-orange">
                <i class="ion-ios-people"></i>
              </div>
              <div class="stats-content">
                <div class="stats-title">Total Drivers</div>
                <div class="stats-number">{{GETDASHBOARDCARDS.totaldrivers}}</div>

                <div class="stats-desc">Total Drivers</div>
              </div>
            </div>
          </div>
          <!-- end col-3 -->
           <!-- begin col-3 -->
           <div class="col-lg-3 col-md-6">
            <div class="widget widget-stats bg-white text-inverse">
              <div class="stats-icon stats-icon-square bg-gradient-orange">
                <i class="ion-ios-people"></i>
              </div>
              <div class="stats-content">
                <div class="stats-title">Total Vehicles</div>
                <div class="stats-number">{{GETDASHBOARDCARDS.totalvehicles}}</div>

                <div class="stats-desc">Total Vehicles</div>
              </div>
            </div>
          </div>
          <!-- end col-3 -->
          <!-- begin col-3 -->
          <div class="col-lg-3 col-md-6">
            <div class="widget widget-stats bg-white text-inverse">
              <div class="stats-icon stats-icon-square bg-gradient-orange">
                <i class="ion-ios-people"></i>
              </div>
              <div class="stats-content">
                <div class="stats-title">Total Bookings</div>
                <div class="stats-number">{{GETDASHBOARDCARDS.totalbooking}}</div>

                <div class="stats-desc">Total Overall Bookings</div>
              </div>
            </div>
          </div>
          <!-- end col-3 -->
          <!-- begin col-3 -->
          <div class="col-lg-3 col-md-6">
            <div class="widget widget-stats bg-white text-inverse">
              <div class="stats-icon stats-icon-square bg-gradient-orange">
                <i class="ion-ios-people"></i>
              </div>
              <div class="stats-content">
                <div class="stats-title">Total Waiting List</div>
                <div class="stats-number">{{GETDASHBOARDCARDS.totalwaitingbooking}}</div>

                <div class="stats-desc">Total Waiting List</div>
              </div>
              
            </div>
          </div>
          <!-- end col-3 -->
         <!-- begin col-3 -->
         <div class="col-lg-3 col-md-6">
            <div class="widget widget-stats bg-white text-inverse">
              <div class="stats-icon stats-icon-square bg-gradient-yellow">
                <i class="ion-ios-people"></i>
              </div>
              <div class="stats-content">
                <div class="stats-title">Total Cancelled Bookings</div>
                <div class="stats-number">{{GETDASHBOARDCARDS.totalcancelledbooked}}</div>

                <div class="stats-desc">Total CheckIn Bookings</div>
              </div>
            </div>
          </div>
          <!-- end col-3 -->
          <!-- begin col-3 -->
          <div class="col-lg-3 col-md-6">
            <div class="widget widget-stats bg-white text-inverse">
              <div class="stats-icon stats-icon-square bg-gradient-yellow">
                <i class="fas fa-taxi"></i>
              </div>
               <div class="stats-content">
                <div class="stats-title">Total Unassigned Bookings</div>
                <div class="stats-number">{{GETDASHBOARDCARDS.totalunassigned}}</div>

                <div class="stats-desc">Total Overall Unassigned Bookings</div>
              </div>
            </div>
          </div>
          <!-- end col-3 -->
          <!-- begin col-3 -->
          <div class="col-lg-3 col-md-6">
            <div class="widget widget-stats bg-white text-inverse">
              <div class="stats-icon stats-icon-square bg-gradient-yellow">
                <i class="fas fa-taxi"></i>
              </div>
              <div class="stats-content">
                <div class="stats-title">Total Passengers Booked</div>
                <div class="stats-number">{{GETDASHBOARDCARDS.totalpassangersbooked}}</div>

                <div class="stats-desc">Total Passangers Booked</div>
              </div>
            </div>
          </div>
          <!-- end col-3 -->
          
          <!-- begin col-3 -->
          <div class="col-lg-3 col-md-6">
            <div class="widget widget-stats bg-white text-inverse">
              <div class="stats-icon stats-icon-square bg-gradient-yellow">
                <i class="fas fa-taxi"></i>
              </div>
              <div class="stats-content">
                <div class="stats-title">Total Driver Tips</div>
                <div class="stats-number">R{{GETDASHBOARDCARDS.totaldrivertips}}</div>

                <div class="stats-desc">Total Driver Tips</div>
              </div>
            </div>
          </div>
          <!-- end col-3 -->
          <!-- begin col-3 -->
          <div class="col-lg-3 col-md-6">
            <div class="widget widget-stats bg-white text-inverse">
              <div class="stats-icon stats-icon-square bg-gradient-yellow">
                <i class="ion-ios-people"></i>
              </div>
              <div class="stats-content">
                <div class="stats-title">Total Upcoming Booking</div>
                <div class="stats-number">{{GETDASHBOARDCARDS.totalupcoming}}</div>

                <div class="stats-desc">Total Upcoming Booking</div>
              </div>
            </div>
          </div>
          <!-- end col-3 -->
           
        </div>

        <!-- end row -->

         <!-- begin row -->
        <div class="row">
          <!-- begin col-8 -->
          <div class="col-xl-12">

                <!-- begin panel -->
            <div class="panel panel-inverse" data-sortable-id="index-1">
              <div class="panel-heading">
                <div class="panel-heading-btn">
                  <a
                    href="javascript:;"
                    class="btn btn-xs btn-icon btn-circle btn-default"
                    data-click="panel-expand"
                  >
                    <i class="fa fa-expand"></i>
                  </a>
                  <a
                    href="javascript:;"
                    class="btn btn-xs btn-icon btn-circle btn-success"
                    data-click="panel-reload"
                  >
                    <i class="fa fa-redo"></i>
                  </a>
                  <a
                    href="javascript:;"
                    class="btn btn-xs btn-icon btn-circle btn-warning"
                    data-click="panel-collapse"
                  >
                    <i class="fa fa-minus"></i>
                  </a>
                  <a
                    href="javascript:;"
                    class="btn btn-xs btn-icon btn-circle btn-danger"
                    data-click="panel-remove"
                  >
                    <i class="fa fa-times"></i>
                  </a>
                </div>
                <h4 class="panel-title">Upcoming Bookings(30 Days)</h4>
              </div>
              <div class="panel-body">
                <div id="interactive-chartOpbyAss" class="height-sm"></div>
              </div>
            </div>
            <!-- end panel -->
          </div>
         
        </div>
        <!-- end row -->


        <!-- begin row -->
        <div class="row">
          <!-- begin col-8 -->
          <div class="col-xl-8">

           <!-- begin panel -->
            <div class="panel panel-inverse" data-sortable-id="index-2">
              <div class="panel-heading">
                <div class="panel-heading-btn">
                  <a
                    href="javascript:;"
                    class="btn btn-xs btn-icon btn-circle btn-default"
                    data-click="panel-expand"
                  >
                    <i class="fa fa-expand"></i>
                  </a>
                  <a
                    href="javascript:;"
                    class="btn btn-xs btn-icon btn-circle btn-success"
                    data-click="panel-reload"
                  >
                    <i class="fa fa-redo"></i>
                  </a>
                  <a
                    href="javascript:;"
                    class="btn btn-xs btn-icon btn-circle btn-warning"
                    data-click="panel-collapse"
                  >
                    <i class="fa fa-minus"></i>
                  </a>
                  <a
                    href="javascript:;"
                    class="btn btn-xs btn-icon btn-circle btn-danger"
                    data-click="panel-remove"
                  >
                    <i class="fa fa-times"></i>
                  </a>
                </div>
                <h4 class="panel-title">Total Confirmed vs Cancelled Booking(Value)</h4>
              </div>
              <div class="panel-body">
                <div id="donut-OperatorByGender" class="height-sm"></div>
              </div>
            </div>
            <!-- end panel -->

            <!-- begin panel -->
            <div class="panel panel-inverse" data-sortable-id="index-3">
              <div class="panel-heading">
                <div class="panel-heading-btn">
                  <a
                    href="javascript:;"
                    class="btn btn-xs btn-icon btn-circle btn-default"
                    data-click="panel-expand"
                  >
                    <i class="fa fa-expand"></i>
                  </a>
                  <a
                    href="javascript:;"
                    class="btn btn-xs btn-icon btn-circle btn-success"
                    data-click="panel-reload"
                  >
                    <i class="fa fa-redo"></i>
                  </a>
                  <a
                    href="javascript:;"
                    class="btn btn-xs btn-icon btn-circle btn-warning"
                    data-click="panel-collapse"
                  >
                    <i class="fa fa-minus">Total Confirmed vs Cancelled Booking</i>
                  </a>
                  <a
                    href="javascript:;"
                    class="btn btn-xs btn-icon btn-circle btn-danger"
                    data-click="panel-remove"
                  >
                    <i class="fa fa-times"></i>
                  </a>
                </div>
                <h4 class="panel-title">Total </h4>
              </div>
              <div class="panel-body">
                <div id="donut-OperatorByAgeGroup" class="height-sm"></div>
              </div>
            </div>
            <!-- end panel -->

              <!-- begin panel -->
            <div class="panel panel-inverse" data-sortable-id="index-4">
              <div class="panel-heading">
                <div class="panel-heading-btn">
                  <a
                    href="javascript:;"
                    class="btn btn-xs btn-icon btn-circle btn-default"
                    data-click="panel-expand"
                  >
                    <i class="fa fa-expand"></i>
                  </a>
                  <a
                    href="javascript:;"
                    class="btn btn-xs btn-icon btn-circle btn-success"
                    data-click="panel-reload"
                  >
                    <i class="fa fa-redo"></i>
                  </a>
                  <a
                    href="javascript:;"
                    class="btn btn-xs btn-icon btn-circle btn-warning"
                    data-click="panel-collapse"
                  >
                    <i class="fa fa-minus"></i>
                  </a>
                  <a
                    href="javascript:;"
                    class="btn btn-xs btn-icon btn-circle btn-danger"
                    data-click="panel-remove"
                  >
                    <i class="fa fa-times"></i>
                  </a>
                </div>
                <h4 class="panel-title">Commuter Age Segment</h4>
              </div>
              <div class="panel-body">
                <div id="VehicleWithNoOperatingLicense" class="height-sm"></div>
              </div>
            </div>
            <!-- end panel -->


              <!-- begin panel -->
            <div class="panel panel-inverse" data-sortable-id="index-4">
              <div class="panel-heading">
                <div class="panel-heading-btn">
                  <a
                    href="javascript:;"
                    class="btn btn-xs btn-icon btn-circle btn-default"
                    data-click="panel-expand"
                  >
                    <i class="fa fa-expand"></i>
                  </a>
                  <a
                    href="javascript:;"
                    class="btn btn-xs btn-icon btn-circle btn-success"
                    data-click="panel-reload"
                  >
                    <i class="fa fa-redo"></i>
                  </a>
                  <a
                    href="javascript:;"
                    class="btn btn-xs btn-icon btn-circle btn-warning"
                    data-click="panel-collapse"
                  >
                    <i class="fa fa-minus"></i>
                  </a>
                  <a
                    href="javascript:;"
                    class="btn btn-xs btn-icon btn-circle btn-danger"
                    data-click="panel-remove"
                  >
                    <i class="fa fa-times"></i>
                  </a>
                </div>
                <h4 class="panel-title">Taxi Finance Chart</h4>
              </div>
              <div class="panel-body">
                <div id="Vehiclefinancechart" class="height-sm"></div>
              </div>
            </div>
            <!-- end panel -->


          </div>

         
          <!-- end col-8 -->
          <!-- begin col-8 -->
          <div class="col-xl-4">

              <!-- begin panel -->
            <div class="panel panel-inverse" data-sortable-id="index-6">
              <div class="panel-heading">
                <div class="panel-heading-btn">
                  <a
                    href="javascript:;"
                    class="btn btn-xs btn-icon btn-circle btn-default"
                    data-click="panel-expand"
                  >
                    <i class="fa fa-expand"></i>
                  </a>
                  <a
                    href="javascript:;"
                    class="btn btn-xs btn-icon btn-circle btn-success"
                    data-click="panel-reload"
                  >
                    <i class="fa fa-redo"></i>
                  </a>
                  <a
                    href="javascript:;"
                    class="btn btn-xs btn-icon btn-circle btn-warning"
                    data-click="panel-collapse"
                  >
                    <i class="fa fa-minus"></i>
                  </a>
                  <a
                    href="javascript:;"
                    class="btn btn-xs btn-icon btn-circle btn-danger"
                    data-click="panel-remove"
                  >
                    <i class="fa fa-times"></i>
                  </a>
                </div>
                <h4 class="panel-title">Top 5 Commuters</h4>
              </div>
              <div class="panel-body p-t-0">
                <div class="table-responsive">
                  <table class="table table-valign-middle">
                    <thead>
                      <tr>
                        <th>Name</th>
                        <th>Trips</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
					  v-for="(item,index) in GETOPERATORFINSTATSTOP5.top5commuterstrips"
                    v-bind:key="item.id"
                    :class="index & 1 ? 'odd gradeA' : 'even'"
					  >
                        <td>
                          {{item.name}}
                        </td>
                        <td>{{item.total | currencywithSymbol }}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <!-- end panel -->

              <!-- begin panel -->
            <div class="panel panel-inverse" data-sortable-id="index-10">
              <div class="panel-heading">
                <div class="panel-heading-btn">
                  <a
                    href="javascript:;"
                    class="btn btn-xs btn-icon btn-circle btn-default"
                    data-click="panel-expand"
                  >
                    <i class="fa fa-expand"></i>
                  </a>
                  <a
                    href="javascript:;"
                    class="btn btn-xs btn-icon btn-circle btn-success"
                    data-click="panel-reload"
                  >
                    <i class="fa fa-redo"></i>
                  </a>
                  <a
                    href="javascript:;"
                    class="btn btn-xs btn-icon btn-circle btn-warning"
                    data-click="panel-collapse"
                  >
                    <i class="fa fa-minus"></i>
                  </a>
                  <a
                    href="javascript:;"
                    class="btn btn-xs btn-icon btn-circle btn-danger"
                    data-click="panel-remove"
                  >
                    <i class="fa fa-times"></i>
                  </a>
                </div>
                <h4 class="panel-title">Top 5 Vehicle</h4>
              </div>
              <div class="panel-body p-t-0">
                <div class="table-responsive">
                  <table class="table table-valign-middle">
                    <thead>
                      <tr>
						            <th>Reg Number</th>
                        <th>Trips</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
					  v-for="(item,index) in GETOPERATORFINSTATSTOP5.top5vehicletrips"
                    v-bind:key="item.id"
                    :class="index & 1 ? 'odd gradeA' : 'even'"
					  >
						<td>
                          {{item.name}}
                        </td>
                        <td>{{item.total}}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <!-- end panel -->

             <!-- begin panel -->
            <div class="panel panel-inverse" data-sortable-id="index-10">
              <div class="panel-heading">
                <div class="panel-heading-btn">
                  <a
                    href="javascript:;"
                    class="btn btn-xs btn-icon btn-circle btn-default"
                    data-click="panel-expand"
                  >
                    <i class="fa fa-expand"></i>
                  </a>
                  <a
                    href="javascript:;"
                    class="btn btn-xs btn-icon btn-circle btn-success"
                    data-click="panel-reload"
                  >
                    <i class="fa fa-redo"></i>
                  </a>
                  <a
                    href="javascript:;"
                    class="btn btn-xs btn-icon btn-circle btn-warning"
                    data-click="panel-collapse"
                  >
                    <i class="fa fa-minus"></i>
                  </a>
                  <a
                    href="javascript:;"
                    class="btn btn-xs btn-icon btn-circle btn-danger"
                    data-click="panel-remove"
                  >
                    <i class="fa fa-times"></i>
                  </a>
                </div>
                <h4 class="panel-title">Top 5 Drivers</h4>
              </div>
              <div class="panel-body p-t-0">
                <div class="table-responsive">
                  <table class="table table-valign-middle">
                    <thead>
                      <tr>
						            <th>Driver Name</th>
                        <th>Trips(R)</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
					  v-for="(item,index) in GETOPERATORFINSTATSTOP5.top5driverstrips"
                    v-bind:key="item.id"
                    :class="index & 1 ? 'odd gradeA' : 'even'"
					  >
						<td>
                          {{item.name}}
                        </td>
                        <td>{{item.total | currencywithSymbol }}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <!-- end panel -->


          </div>
          <!-- end col-8 -->
         
        </div>
        <!-- end row -->

      
        <!-- begin row -->

        <!-- begin #Main Body Template -->
      </div>
    </transition>
    <!-- end #content -->

    <!-- begin scroll to top btn -->
    <pagescroller />
    <!-- end scroll to top btn -->
  </div>
</template>

<script>
// @ is an alias to /src
//import LoginComp from '@/components/LoginComp.vue'

import statsCards from "@/components/charts/statsCards.vue";
import headerComp from "@/components/common/header.vue";
import headerpagebreadcrumb from "@/components/common/pageheader-breadcrumb.vue";
import pageloader from "@/components/common/pageloader.vue";
import pagescroller from "@/components/common/pagescroller.vue";
import sidebarComp from "@/components/common/sidebar.vue";

import { createNamespacedHelpers } from "vuex";
const { mapGetters, mapActions } = createNamespacedHelpers(
  "tpaybookings_dashboard"
);

export default {
  name: "regionalchairman_dashboard",
  data: function() {
    return {
      message: "Some Message",
      ROLES: this.$store.getters["auth/GETUSERROLES"],
      USERPROFILE: this.$store.getters["auth/USERPROFILE"],
      taxiassociationid: 2,
      GETDASHBOARDCARDS: {},
      GETOPERATORFINSTATS: {},
      GETOPERATORFINSTATSTOP5: {}
    };
  },
  beforeCreate: function() {
    document.body.classList.remove("bg-white");
  },
  components: {
    headerpagebreadcrumb,
    statsCards,
    headerComp,
    sidebarComp,
    pagescroller,
    pageloader
  },
  computed: {
    ...mapGetters(["", ""])
  },
  methods: {
    ...mapActions(["getdashboardcards", "getoperatorfinstats","getoperatorfinstatstop5"]),
    init: function() {}
  },
  mounted: function() {
    $(document).data("context", this);
    App.init();

    const router = this.$router;

    var payload = {
      regionid: this.taxiassociationid
    };

    this.getdashboardcards({ router, payload })
      .then(parsedResultOP => {
        //const context = $(document).data("context");

        

        this.GETDASHBOARDCARDS = parsedResultOP;

        handleOperatorByGender();
      })
      .catch(error => {
        //context.$wait.end("generatestatement");
      });

    this.getoperatorfinstats({ router, payload })
      .then(parsedResultOP => {
        const context = $(document).data("context");

        

        context.GETOPERATORFINSTATS = parsedResultOP;

    // handleOperatorByAgeGroup();
		// handleVehicleWithNoOperatingLicense();
    handleTotalOpPerTaxiAssBarChart(parsedResultOP[0]);
    // handleVehicleWithfinancechartdisk();

    
      })
      .catch(error => {
        //context.$wait.end("generatestatement");
      });


      this.getoperatorfinstatstop5({ router, payload }).then(parsedResultOP => {
        const context = $(document).data("context");
        
        context.GETOPERATORFINSTATSTOP5 = parsedResultOP;
      })  
      .catch(error => {
        //context.$wait.end("generatestatement");
      });

    var handleOperatorByAgeGroup = function() {
      "use strict";
      if ($("#donut-OperatorByAgeGroup").length !== 0) {
        const context = $(document).data("context");

       const colors = [COLOR_GREEN,COLOR_RED];

        var operatorAgeGroupData = [
          {
              label: 'Send Money',
              data: 10000,
              color: colors[0]
            },
            {
              label: 'Withdrawals',
              data: 9000,
              color: colors[1]
            }
        ]

        $.plot("#donut-OperatorByAgeGroup", operatorAgeGroupData, {
          series: {
            pie: {
              innerRadius: 0.5,
              show: true,
              label: {
                show: true
              }
            }
		  },
		  grid: {
            hoverable: true,
            clickable: true
          },
          legend: {
            show: true
          }
        });
      }
    };

    var handleOperatorByGender = function() {
      "use strict";
      if ($("#donut-OperatorByGender").length !== 0) {
        const context = $(document).data("context");

        const colors = [COLOR_GREEN,COLOR_RED];
        

        

        var operatorGenderData = [
          {
              label: 'Bookings(R' + context.GETDASHBOARDCARDS.totalbookingsvalue + ')',
              data: parseFloat(context.GETDASHBOARDCARDS.totalbookingsvalue),
              color: colors[0]
            },
            {
              label: 'Cancellations(R' + context.GETDASHBOARDCARDS.totalcanceledbookingvalue + ')',
              data: parseFloat(context.GETDASHBOARDCARDS.totalcanceledbookingvalue),
              color: colors[1]
            }
        ]

        $.plot("#donut-OperatorByGender", operatorGenderData, {
          series: {
            pie: {
              innerRadius: 0.5,
              show: true,
              label: {
                show: true
              }
            }
          },
          grid: {
            hoverable: true,
            clickable: true
          },
          legend: {
            show: true
          }
        });
      }
	};
	
	 var handleVehicleWithNoOperatingLicense = function() {
      "use strict";
      if ($("#VehicleWithNoOperatingLicense").length !== 0) {
        const context = $(document).data("context");

		const colors = [COLOR_PURPLE_LIGHTER, COLOR_BLUE];
		

        var nooperatinglicense = [
			{
            label: `Under 35`,
            data: 50,
            color: COLOR_GREEN
          },
          {
            label: `Over 35`,
            data: 30,
            color: COLOR_RED
          },
          {
            label: `Over 60`,
            data: 10,
            color: COLOR_PURPLE_LIGHTER
          },
        ];

        $.plot("#VehicleWithNoOperatingLicense", nooperatinglicense, {
          series: {
            pie: {
              innerRadius: 0.5,
              show: true,
              label: {
                show: true
              }
            }
          },
          grid: {
            hoverable: true,
            clickable: true
          },
          legend: {
            show: true
          }
        });
      }
	};

var handleTotalOpPerTaxiAssBarChart = function (json_data) {
'use strict';
	
	if ($('#interactive-chartOpbyAss').length !== 0) {

    const context = $(document).data("context");

    //var json_data = context.GETOPERATORFINSTATS;

  var data_dep = [];
  var data_pay = [];
  var data_30days = [];

   

  for(var i in json_data.days){
    data_dep.push(json_data.totalupcoming[i].totalupcoming);
    data_pay.push(json_data.totalwaitinglist[i].totalwaitinglist);
    data_30days.push(json_data.days[i].days);
  }
    
//     var data_dep =  [
//     23,
//     211,
//     147,
//     24,
//     63,
//     66,
//     20,
//     298,
//     27,
//     40,
//     323,
//     142,
//     671,
//     56,
//     119,
//     65,
//     191,
//     30,
//     49,
//     103,
//     36,
//     27,
//     34,
//     29,
//     90,
//     29,
//     27,
//     34,
//     29,
//     90,
//     29,
// ];

    
//      var data_pay =  [
//     32,
//     363,
//     303,
//     32,
//     140,
//     131,
//     46,
//     542,
//     43,
//     88,
//     853,
//     306,
//     1510,
//     229,
//     450,
//     108,
//     471,
//     56,
//     80,
//     282,
//     91,
//     49,
//     74,
//     131,
//     381,
//     54,
//     49,
//     74,
//     131,
//     381,
//     54
// ]

       

//         var data_30days =  [
//           '1 Jul','2 Jul','3 Jul','4 Jul','4 Jul','5 Jul','6 Jul','7 Jul','8 Jul','9 Jul','10 Jul',
//           '11 Jul','12 Jul','13 Jul','14 Jul','15 Jul','16 Jul','17 Jul','18 Jul','19 Jul','20 Jul','21 Jul',
//           '22 Jul','23 Jul','24 Jul','25 Jul','26 Jul','27 Jul','28 Jul','29 Jul','30 Jul','31 Jul',
//         ]

          
	
	var options = {
		chart: {
			height: 350,
			type: 'bar'
		},
		title: {
			text: 'Daily Booking vs Waiting List',
			align: 'center'
		},
		plotOptions: {
			bar: {
				horizontal: false,
				columnWidth: '100%',
        endingShape: 'rounded',
        dataLabels: {
          position: 'top'
        }
			},
		},
		dataLabels: {
      enabled: true
		},
		stroke: {
			show: true,
			width: 2,
			colors: ['transparent']
		},
		colors: [COLOR_GREEN,COLOR_RED],
		series: [{
			name: 'Upcoming Bookings',
			data: data_dep
		}, {
			name: 'Waiting Lists',
			data: data_pay
		}],
		xaxis: {
			categories: data_30days,
			axisBorder: {
				show: true,
				color: COLOR_SILVER_TRANSPARENT_5,
				height: 1,
				width: '100%',
				offsetX: 0,
				offsetY: -1
			},
			axisTicks: {
				show: true,
				borderType: 'solid',
				color: COLOR_SILVER,
				height: 6,
				offsetX: 0,
				offsetY: 0
			}
		},
		yaxis: {
			title: {
				text: '$ (thousands)'
			}
		},
		fill: {
			opacity: 1
		},
		tooltip: {
			y: {
				formatter: function (val) {
					return + val
				}
			}
		}
	};
	
	var chart = new ApexCharts(
		document.querySelector('#interactive-chartOpbyAss'),
		options
	);

	chart.render();
	
	}
};

var handleVehicleWithfinancechartdisk = function () {
'use strict';
	
	if ($('#Vehiclefinancechart').length !== 0) {

    const context = $(document).data("context");


    var data_ =  context.GETOPERATORFINSTATS.vehiclefinchat.map((item, index) => {
          return item.numberofvehicles
        });
    
     var data_fdi =  context.GETOPERATORFINSTATS.vehiclefinchat.map((item, index) => {
          return item.fdi
        });
	
	var options = {
		chart: {
			height: 350,
			type: 'bar'
		},
		title: {
			text: 'Taxi Finance Chart',
			align: 'center'
		},
		plotOptions: {
			bar: {
				horizontal: false,
				columnWidth: '100%',
        endingShape: 'rounded',
        dataLabels: {
          position: 'top'
        }
			},
		},
		dataLabels: {
			enabled: true
		},
		stroke: {
			show: true,
			width: 2,
			colors: ['transparent']
		},
		colors: [COLOR_BLUE, COLOR_GREEN],
		series: [{
			name: 'Operators',
			data: data_
		}],
		xaxis: {
			categories: data_fdi,
			axisBorder: {
				show: true,
				color: COLOR_SILVER_TRANSPARENT_5,
				height: 1,
				width: '100%',
				offsetX: 0,
				offsetY: -1
			},
			axisTicks: {
				show: true,
				borderType: 'solid',
				color: COLOR_SILVER,
				height: 6,
				offsetX: 0,
				offsetY: 0
			}
		},
		yaxis: {
			title: {
				text: ''
			}
		},
		fill: {
			opacity: 1
		},
		tooltip: {
			y: {
				formatter: function (val) {
					return + val
				}
			}
		}
	};
	
	var chart = new ApexCharts(
		document.querySelector('#Vehiclefinancechart'),
		options
	);

	chart.render();
	
	}
};
  

   
  }
};
</script>

<style>
@import "/assets/plugins/nvd3/build/nv.d3.css";
</style>